import * as React from "react";
import TeethRow from "../TeethRow";
import ToothEditor from "./ToothEditor";
import Tooth from "../Tooth";
import BottomBar from "./BottomBar";
import "../../styles/TeethChart.css";
import strings from "../../res/strings";
import Warning from "./Warning";

type MyProps = {
  nettskjema: any;
  nettskjemaKey: string;
  patientIdentification: string | null;
};

type MyState = {
  loading: boolean;
  pasient: any;
  allToothComponentRefs: Object;
  TCSelectedTeeth: Array<Tooth> | null;
  submitStatus: number;
  printOnNextUpdate: boolean;
  hasPrinted: boolean;
  shiftKeyDown: boolean | null;
  mouseIsDown: boolean | null;
  showIds: boolean;
  showTips: boolean;
};

class TeethChart extends React.Component<MyProps, MyState> {
  showingEditor: boolean;
  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      pasient: {},
      TCSelectedTeeth: null,
      allToothComponentRefs: {},
      shiftKeyDown: null,
      submitStatus: 0.0,
      printOnNextUpdate: false,
      mouseIsDown: null,
      hasPrinted: false,
      showIds: true,
      showTips: false,
    };
    this.toothClickHandler = this.toothClickHandler.bind(this);
    this.assemblePatient = this.assemblePatient.bind(this);
    this.assembleBackUpPatient = this.assembleBackUpPatient.bind(this);
    this.keyDownListener = this.keyDownListener.bind(this);
    this.addToothToSelected = this.addToothToSelected.bind(this);
    this.keyUpListener = this.keyUpListener.bind(this);
    this.removeTfromArr = this.removeTfromArr.bind(this);
    this.submitTeethData = this.submitTeethData.bind(this);
    this.unselectAllTeeth = this.unselectAllTeeth.bind(this);
    this.selectAllTeeth = this.selectAllTeeth.bind(this);
    this.recover = this.recover.bind(this);
    this.getInfoForPrintString = this.getInfoForPrintString.bind(this);
    this.getToothDataString = this.getToothDataString.bind(this);
    this.print = this.print.bind(this);
    this.showInfo = this.showInfo.bind(this);
    this.getInfoText = this.getInfoText.bind(this);
    this.isToothInArr = this.isToothInArr.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.printCheckSubmit = this.printCheckSubmit.bind(this);
    this.afterPrint = this.afterPrint.bind(this);
    this.toggleTips = this.toggleTips.bind(this);
    this.showEditor = this.showEditor.bind(this);
    this.changesHaveNotBeenPrinted = this.changesHaveNotBeenPrinted.bind(this);
    this.updateToothComponentsOverview = this.updateToothComponentsOverview.bind(
      this,
    );
    this.showingEditor = false;
  }

  updateToothComponentsOverview(newRefs: any) {
    //TODO sjekk om det er nok å se om det er nok å sende en gang
    //TODO funker bare for en rad siden state overskriver?
    let tempOverview = this.state.allToothComponentRefs;
    for (var newRef in newRefs) {
      if (newRefs.hasOwnProperty(newRef)) {
        //for hver nye ref
        tempOverview[newRef] = newRefs[newRef];
      }
    }
    this.setState({ allToothComponentRefs: tempOverview });
  }

  onMouseDown(): void {
    this.setState({ mouseIsDown: true });
  }

  onMouseUp(): void {
    this.setState({ mouseIsDown: false });
  }

  keyDownListener(keyEvent: KeyboardEvent): void | boolean {
    if (keyEvent && keyEvent.keyCode) {
      if (
        keyEvent.keyCode === 27 ||
        (keyEvent.keyCode === 65 && !(keyEvent.ctrlKey || keyEvent.metaKey))
      ) {
        if (this.state.showTips) {
          this.toggleTips();
        } else {
          this.unselectAllTeeth(true);
        }
      } else if (keyEvent.keyCode === 16) {
        this.setState({ shiftKeyDown: true });
      } else if (
        keyEvent.keyCode === 65 &&
        (keyEvent.ctrlKey || keyEvent.metaKey)
      ) {
        this.selectAllTeeth();
        keyEvent.preventDefault();
      } else if (!(keyEvent.ctrlKey || keyEvent.metaKey)) {
        if (keyEvent.keyCode === 37) {
          this.tabBy(-1);
        } else if (keyEvent.keyCode === 38) {
          let halfCount = 16;
          if (
            this.state.pasient &&
            this.state.pasient.toothData &&
            Object.keys(this.state.pasient.toothData)
          ) {
            halfCount = Object.keys(this.state.pasient.toothData).length / 2;
          }
          this.tabBy(-halfCount);
        } else if (keyEvent.keyCode === 39) {
          this.tabBy(1);
        } else if (keyEvent.keyCode === 40) {
          let halfCount = 16;
          if (
            this.state.pasient &&
            this.state.pasient.toothData &&
            Object.keys(this.state.pasient.toothData)
          ) {
            halfCount = Object.keys(this.state.pasient.toothData).length / 2;
          }
          this.tabBy(halfCount);
        } else if (keyEvent.keyCode === 73) {
          var tempB = this.state.showIds;
          this.setState({ showIds: !tempB });
        } else if (keyEvent.keyCode === 83) {
          if (window.confirm(strings.sendShortCutInfo)) {
            this.submitTeethData();
          }
        } else if (keyEvent.keyCode === 76 || keyEvent.keyCode === 80) {
          this.print();
        } else if (keyEvent.keyCode === 84) {
          this.toggleTips();
        }
      }
    }
  }

  keyUpListener(keyEvent: KeyboardEvent): void {
    if (keyEvent.keyCode === 16) {
      this.setState({ shiftKeyDown: false });
    }
  }

  tabBy(i: number): void {
    let n = document.activeElement;
    while (i !== 0) {
      if (n) {
        let m;
        i > 0 ? (m = n.nextElementSibling) : (m = n.previousElementSibling);
        if (m && m.className.indexOf("Tooth") !== -1) {
          //indexof
          n = m;
        } else {
          //Må bytte til andre tannrad
          if (i !== 0) {
            if (n && n.parentElement) {
              if (i > 0) {
                if (
                  n.parentElement.nextElementSibling &&
                  n.parentElement.nextElementSibling.className &&
                  n.parentElement.nextElementSibling.className.indexOf(
                    "TeethRow",
                  ) !== -1 &&
                  n.parentElement.nextElementSibling.firstChild &&
                  n.parentElement.nextElementSibling.firstChild instanceof
                    HTMLElement
                ) {
                  n = n.parentElement.nextElementSibling.firstChild;
                }
              } else {
                if (
                  n.parentElement.previousElementSibling &&
                  n.parentElement.previousElementSibling.className &&
                  n.parentElement.previousElementSibling.className.indexOf(
                    "TeethRow",
                  ) !== -1 &&
                  n.parentElement.previousElementSibling.lastChild &&
                  n.parentElement.previousElementSibling.lastChild instanceof
                    HTMLElement
                ) {
                  n = n.parentElement.previousElementSibling.lastChild;
                }
              }
            }
          }
        }
      }
      i > 0 ? i-- : i++;
    }
    if (
      n &&
      n instanceof HTMLElement &&
      n.className &&
      n.className.indexOf("Tooth") !== -1
    ) {
      n.focus();
    }
  }

  selectAllTeeth(): void {
    console.log("TODO: velg alle tenner");
  }

  unselectAllTeeth(setFocusOnLastTooth: boolean): void {
    if (this.state.TCSelectedTeeth && this.state.TCSelectedTeeth.length > 0) {
      for (let i = this.state.TCSelectedTeeth.length - 1; i >= 0; i--) {
        if (this.state.TCSelectedTeeth[i].state.highlighted) {
          if (this.state.TCSelectedTeeth[i].toothElement.current !== null) {
            if (this.state.TCSelectedTeeth && this.state.TCSelectedTeeth[i]) {
              this.state.TCSelectedTeeth[i].setHighlighting(false);
            }
          }
        }
      }
      if (
        setFocusOnLastTooth &&
        this.state.TCSelectedTeeth &&
        this.state.TCSelectedTeeth[this.state.TCSelectedTeeth.length - 1]
      ) {
        this.state.TCSelectedTeeth[
          this.state.TCSelectedTeeth.length - 1
        ].focusMe();
      }
      this.setState({
        TCSelectedTeeth: null,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.keyUpListener, false);
    document.removeEventListener("mouseup", this.onMouseUp, false);
    document.removeEventListener("mousedown", this.onMouseDown, false);
  }

  componentDidMount() {
    this.setState({ loading: true });
    let pat = this.assemblePatient();
    this.setState({
      loading: false,
      pasient: pat,
    });
    document.addEventListener("keyup", this.keyUpListener, false);
    document.addEventListener("mouseup", this.onMouseUp, false);
    document.addEventListener("mousedown", this.onMouseDown, false);
    if (this.props.patientIdentification) {
      document.title =
        strings.pageTitle + " for " + this.props.patientIdentification;
    }
  }

  assemblePatient(): any {
    let toothDataFromNettskjema;
    let pat;
    pat = {};
    pat.toothData = {};
    if (this.props.nettskjema && this.props.nettskjema.elements) {
      toothDataFromNettskjema = this.getElementWithObjKeyVal(
        this.props.nettskjema.elements,
        "elementId",
        1789131,
      );
      let idTextQuestion = this.getElementWithObjKeyVal(
        this.props.nettskjema.elements,
        "elementId",
        1791766,
      );
      pat.identification = [
        this.getElementWithObjKeyVal(
          idTextQuestion.questions,
          "questionId",
          1813829,
        ).questionId,
        this.props.nettskjemaKey,
      ];
      if (toothDataFromNettskjema && toothDataFromNettskjema.questions) {
        for (let i = 0; i < toothDataFromNettskjema.questions.length; i++) {
          pat.toothData[i] = {};
          pat.toothData[i].id = toothDataFromNettskjema.questions[i].text;
          pat.toothData[i].codebookId =
            toothDataFromNettskjema.questions[i].questionId;
          pat.toothData[i].states = {};
          if (toothDataFromNettskjema.answerOptions) {
            for (
              let j = 0;
              j < toothDataFromNettskjema.answerOptions.length;
              j++
            ) {
              pat.toothData[i].states[
                toothDataFromNettskjema.answerOptions[j].externalAnswerOptionId
              ] = {
                id: j,
                question: toothDataFromNettskjema.answerOptions[j].text,
                answer: toothDataFromNettskjema.answerOptions[j].preselected,
                codebookName:
                  toothDataFromNettskjema.answerOptions[j].answerOptionId,
              };
            }
          } else {
            console.error("No answer options in tooth data from Nettskjema:");
            console.error(toothDataFromNettskjema);
          }
        }
      } else {
        console.error("Unexpected tooth data from Nettskjema:");
        console.error(toothDataFromNettskjema);
        if (
          true /* window.confirm(strings.noTemplate)//Trenger ikke spørre to ganger?*/
        ) {
          pat = this.assembleBackUpPatient(pat);
        } else {
          pat = strings.aborted;
        }
      }
    } else {
      console.error("Unexpected data from Nettskjema: ", this.props.nettskjema);
      if (
        true /* window.confirm(strings.noTemplate)//Trenger ikke spørre to ganger?*/
      ) {
        pat = this.assembleBackUpPatient(pat);
      } else {
        pat = strings.aborted;
      }
    }
    return pat;
  }

  assembleBackUpPatient(patB): any {
    const queryString = window.location.search;

    console.log("QYERT", queryString);
    patB.identification = [
      1813829,
      "Could not get data from Nettskjema template. Therefore: No valid question-ids present in this answer, answer built on old Nettskjema template. Must be re-sent and corrected manualy. " +
        this.props.nettskjemaKey,
    ];
    let backUpTemplate = require("../../res/backupTeethTemplate.json");
    if (backUpTemplate.questions) {
      for (let i = 0; i < backUpTemplate.questions.length; i++) {
        patB.toothData[i] = {};
        patB.toothData[i].id = backUpTemplate.questions[i].text;
        patB.toothData[i].codebookId = backUpTemplate.questions[i].questionId;
        patB.toothData[i].states = {};
        if (backUpTemplate.answerOptions) {
          for (let j = 0; j < backUpTemplate.answerOptions.length; j++) {
            patB.toothData[i].states[
              backUpTemplate.answerOptions[j].externalAnswerOptionId
            ] = {
              id: j,
              question: backUpTemplate.answerOptions[j].text,
              answer: backUpTemplate.answerOptions[j].preselected,
              codebookName: backUpTemplate.answerOptions[j].answerOptionId,
            };
          }
        } else {
          console.error("Backup-template is missing answerOptions");
          console.error(backUpTemplate);
        }
      }
    } else {
      console.error("Backup-template is missing questions");
      console.error(backUpTemplate);
    }
    return patB;
  }

  componentDidUpdate() {
    if (this.state.printOnNextUpdate) {
      window.print();
      this.setState({ printOnNextUpdate: false });
    }
  }

  getElementWithObjKeyVal(arr, key, val): any | null {
    for (var arrElement in arr) {
      if (arr.hasOwnProperty(arrElement)) {
        for (var obj in arr[arrElement]) {
          if (obj.hasOwnProperty(arrElement)) {
            if (obj === key) {
              if (val === null) {
                return val;
              }
              if (arr[arrElement][obj] === val) {
                return arr[arrElement];
              }
            }
          }
        }
      }
    }
    return null;
  }

  removeTfromArr(t: Tooth, arr: Array<Tooth>): Array<Tooth> {
    if (arr) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].props.id === t.props.id) {
          arr.splice(i, 1);
          i -= 1;
        }
      }
    }
    return arr;
  }

  toothClickHandler(aTooth: Tooth, event: any | null): void {
    if (aTooth) {
      if (event) {
        if (event.type === "mouseenter") {
          if (this.state.mouseIsDown && !aTooth.state.highlighted) {
            this.addToothToSelected(aTooth);
          }
        } else if (event.type === "touchmove") {
          if (!aTooth.state.highlighted) {
            this.addToothToSelected(aTooth);
          }
        } else if (event.type === "mouseleave") {
          //Kommer bare fra tooth om den også ikke er highlighted
          if (this.state.mouseIsDown) {
            this.addToothToSelected(aTooth);
          }
        } else {
          //Antar at type da er keydown eller mousedown, at denne funksjonen ikke kalles med andre typer events
          if (event.shiftKey) {
            if (aTooth.state.highlighted) {
              aTooth.setHighlighting(false);
              this.setState((prevState) => {
                if (prevState.TCSelectedTeeth) {
                  let tempArr = this.removeTfromArr(
                    aTooth,
                    prevState.TCSelectedTeeth,
                  );
                  return { TCSelectedTeeth: tempArr };
                }
                return { TCSelectedTeeth: null };
              });
            } else {
              this.addToothToSelected(aTooth);
            }
          } else {
            //TODO denne burde ikke kjøre når en trykker og drar fra en allerede valgt tann
            this.unselectAllTeeth(false);
            if (aTooth.state.highlighted) {
              aTooth.setHighlighting(false);
            } else {
              aTooth.setHighlighting(true);
              this.setState({ TCSelectedTeeth: [aTooth] });
            }
          }
        }
      }
    }
  }

  addToothToSelected(aTooth: Tooth) {
    aTooth.setHighlighting(true);
    this.setState((prevState) => {
      let tempArr = prevState.TCSelectedTeeth;
      if (tempArr && tempArr.length > 0) {
        if (!this.isToothInArr(aTooth, tempArr)) {
          tempArr.push(aTooth);
        }
      } else {
        tempArr = [aTooth];
      }
      return { TCSelectedTeeth: tempArr };
    });
  }

  isToothInArr(tooth: Tooth, arr: Array<Tooth>): boolean {
    if (arr && tooth) {
      let i: number;
      for (i = 0; i < arr.length; i++) {
        if (
          arr[i] &&
          arr[i].props &&
          arr[i].props.id &&
          tooth.props &&
          tooth.props.id &&
          tooth.props.id === arr[i].props.id
        ) {
          return true;
        }
      }
    }
    return false;
  }

  submitTeethData(): void {
    this.setState({ submitStatus: 0.1 });
    this.unselectAllTeeth(false);
    let teethDataToSend = new Map();
    if (this.state.pasient && this.state.pasient.toothData) {
      for (var tData in this.state.pasient.toothData) {
        if (this.state.pasient.toothData.hasOwnProperty(tData)) {
          let statesArray: number[] = [];
          for (var tState in this.state.pasient.toothData[tData].states) {
            if (
              this.state.pasient.toothData[tData].states.hasOwnProperty(
                tState,
              ) &&
              this.state.pasient.toothData[tData].states[tState].answer
            )
              statesArray.push(
                this.state.pasient.toothData[tData].states[tState].codebookName,
              );
          }
          if (statesArray && statesArray.length > 0) {
            teethDataToSend.set(
              "answersAsMap[" +
                this.state.pasient.toothData[tData].codebookId +
                "].answerOptions",
              statesArray,
            );
          }
        }
      }
    } else {
      console.error("Patient data structure corrupted");
      return;
    }

    var form_data = new FormData();
    teethDataToSend.forEach(function (
      value: any,
      key: any,
      map: Map<any, any>,
    ) {
      value.forEach((state) => {
        form_data.append(key, state);
      });
    });

    form_data.append(
      "answersAsMap[" + this.state.pasient.identification[0] + "].textAnswer",
      this.state.pasient.identification[1],
    );

    const url = "https://nettskjema.no/answer/deliver.json";
    const formId = 131603;
    fetch(url + `?formId=${formId}`, {
      mode: "no-cors",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      body: form_data,
    })
      .then(() => {
        setTimeout(() => {
          this.setState({ submitStatus: 1 });
        }, 1000);
        setTimeout(() => {
          this.setState({ submitStatus: 2 });
        }, 1750); //Gir inntrykket av at vi bruker 2sec mer på å sende data. Er det 2 sek vi vil spare i stedet for å ha animasjon?
        setTimeout(() => {
          this.setState({ pasient: null });
        }, 5000);
      })
      .catch(() => {
        setTimeout(() => {
          this.setState({ submitStatus: -1 });
        }, 2500); //Gir inntrykket av at vi bruker 3sec mer på å sende data. Er det 3 sek vi vil spare i stedet for å ha animasjon?
      });
  }

  recover(): void {
    this.setState({ submitStatus: 0 });
  }

  afterPrint(): void {
    if (!this.state.hasPrinted) {
      this.setState({ hasPrinted: true });
    }
  }

  changesHaveNotBeenPrinted(): void {
    if (this.state.hasPrinted) {
      this.setState({ hasPrinted: false });
    }
  }

  printCheckSubmit(): void {
    if (!this.state.hasPrinted) {
      if (window.confirm(strings.wannaPrint)) {
        this.submitTeethData();
      } else {
        this.print();
      }
    } else {
      this.submitTeethData();
    }
  }

  print(): void {
    this.unselectAllTeeth(false);
    this.setState({ printOnNextUpdate: true, showIds: true });
  }

  showInfo(): boolean {
    //Returnerer false under testing
    return false; // (this.props.nettskjema === strings.usingBackup) || (typeof (this.props.nettskjemaKey) === "string" && this.props.nettskjemaKey.indexOf(strings.noKey) > -1)
  }

  getInfoText(): string {
    let s = "";
    if (this.props.nettskjema === strings.usingBackup) {
      s += strings.usingBackup;
    }
    if (
      typeof this.props.nettskjemaKey === "string" &&
      this.props.nettskjemaKey.indexOf(strings.noKey) > -1
    ) {
      s += "\n" + strings.noKey + " " + strings.noKeyConsequences;
    }
    return s;
  }

  getToothDataString(): string {
    return ""; //TODO om tannpleiere skal fortsette å printe ut
  }

  getInfoForPrintString(): string {
    let nowDate = new Date();
    if (this.props.patientIdentification) {
      return (
        this.props.patientIdentification +
        ", " +
        nowDate.toLocaleString() +
        "\n\n" +
        this.getToothDataString()
      );
    }
    return nowDate.toLocaleString();
  }

  toggleTips() {
    this.setState((prevState) => ({
      showTips: !prevState.showTips,
    }));
  }

  showEditor() {
    if (this.state.submitStatus >= 1) {
      this.showingEditor = true;
      return true;
    }
    if (this.state.mouseIsDown) {
      return this.showingEditor;
    } else {
      if (
        !this.state.TCSelectedTeeth ||
        (this.state.TCSelectedTeeth && this.state.TCSelectedTeeth.length < 1)
      ) {
        this.showingEditor = false;
        return false;
      } else {
        this.showingEditor = true;
        return true;
      }
    }
  }

  render() {
    let showE = this.showEditor();
    return (
      <div className="TeethChart center" style={{ animation: `smoothFade 1s` }}>
        {this.state.loading ? (
          <h1 className="center" style={{ animation: `fadeIn 1s` }}>
            {strings.building}
          </h1> //TODO erstatt med/legg til spinner? Har ikke sett denne teksten før. Så kanskje ikke nødvendig her
        ) : this.state.pasient === strings.aborted ? (
          <h1 className="center" style={{ animation: `fadeIn 1s` }}>
            {strings.aborted}
          </h1>
        ) : (
          <div className="TeethChartLoaded">
            {this.showInfo() ? <Warning text={this.getInfoText()} /> : null}
            {this.state.printOnNextUpdate ? (
              <b className="infoForPrintOut">{this.getInfoForPrintString()}</b>
            ) : null}
            <div className="flexSpaceBetween">
              {this.state.submitStatus === 0 && (
                <div className="topBlank"></div>
              )}
              <div className="flexCenter">
                <TeethRow
                  key="TC1"
                  id=" øverst"
                  shareRefs={this.updateToothComponentsOverview}
                  toothChartAction={this.toothClickHandler}
                  toothData={this.state.pasient && this.state.pasient.toothData}
                  upper={true}
                  moveForEditor={showE}
                  submitStatus={this.state.submitStatus}
                  showIds={this.state.showIds}
                />

                <ToothEditor
                  selectedTeeth={this.state.TCSelectedTeeth}
                  toothComponentRefs={this.state.allToothComponentRefs}
                  submitStatus={this.state.submitStatus}
                  recover={this.recover}
                  keydown={this.keyDownListener}
                  dataChanged={this.changesHaveNotBeenPrinted}
                  show={showE}
                  isCurrentlySelecting={this.state.mouseIsDown}
                />

                <TeethRow
                  key="TC2"
                  id=" nederst"
                  shareRefs={this.updateToothComponentsOverview}
                  toothChartAction={this.toothClickHandler}
                  toothData={this.state.pasient && this.state.pasient.toothData}
                  upper={false}
                  moveForEditor={showE}
                  submitStatus={this.state.submitStatus}
                  showIds={this.state.showIds}
                />
              </div>

              {this.state.submitStatus === 0 && (
                <BottomBar
                  submitRequested={() => this.printCheckSubmit()}
                  printRequested={() => this.print()}
                  afterPrint={() => this.afterPrint()}
                  unselect={() => this.unselectAllTeeth(false)}
                  toggleTips={() => this.toggleTips()}
                  showTips={this.state.showTips}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default TeethChart;
