import * as React from "react";
import { RouteProps } from "react-router";
import strings from "../../res/strings";
import "../../styles/Information.css";
import history from "./history";
type MyProps = {
  topic: string;
  topicId: string;
};

type MyState = {
  htmlContent: string;
};

class Information extends React.Component<MyProps & RouteProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      htmlContent: "",
    };
    this.buildInfoPageFromTopic = this.buildInfoPageFromTopic.bind(this);
    this.insertExternalLinksInText = this.insertExternalLinksInText.bind(this);
    this.isIndexWithinHref = this.isIndexWithinHref.bind(this);
  }

  componentDidMount() {
    this.buildInfoPageFromTopic();
  }

  buildInfoPageFromTopic() {
    let jsonContent: any;
    let htmlContent = "";
    let externalTopicsToLink;
    jsonContent = localStorage.getItem(strings.contentFromNettskjemaKey);
    externalTopicsToLink = localStorage.getItem(
      strings.externalTopicsToLinkKey,
    );
    if (externalTopicsToLink) {
      externalTopicsToLink = JSON.parse(externalTopicsToLink);
      if (
        !externalTopicsToLink ||
        (externalTopicsToLink && externalTopicsToLink.constructor !== Object)
      ) {
        externalTopicsToLink = null;
      }
    }
    if (jsonContent) {
      jsonContent = JSON.parse(jsonContent);
      if (jsonContent && typeof jsonContent === "object") {
        let inATitleDiv = false;
        let inAnImgDiv = false;
        let thisTopicLinks = [];
        for (var contentTag in jsonContent) {
          if (jsonContent.hasOwnProperty(contentTag)) {
            let contentTagID = contentTag.split(/[^A-Za-z0-9]/);
            let lowerCaseTag = contentTag.toLowerCase();
            if (
              (contentTagID && contentTagID[0] === this.props.topicId) ||
              lowerCaseTag.includes(this.props.topicId + " ")
            ) {
              if (lowerCaseTag.indexOf("overskrift") > -1) {
                if (inAnImgDiv) {
                  inAnImgDiv = false;
                  htmlContent += "</div>";
                }
                if (inATitleDiv) {
                  htmlContent += "</div>";
                } else {
                  inATitleDiv = true;
                }
                htmlContent += "<div class='infoSsection'>";
                htmlContent += jsonContent[contentTag];
              } else if (lowerCaseTag.indexOf("lenketekst") > -1) {
                if (jsonContent[contentTag]) {
                  let selfLinks = jsonContent[contentTag].split(",");
                  selfLinks = selfLinks.map((link) =>
                    link.toLowerCase().trim(),
                  );
                  thisTopicLinks = selfLinks;
                }
              } else if (lowerCaseTag.indexOf("tekst") > -1) {
                if (inAnImgDiv) {
                  inAnImgDiv = false;
                  htmlContent += "</div>";
                }
                if (!inATitleDiv) {
                  inATitleDiv = true;
                  htmlContent += "<div class='infoSsection'>";
                }
                htmlContent += this.insertExternalLinksInText(
                  jsonContent[contentTag],
                  thisTopicLinks,
                  externalTopicsToLink,
                );
              } else if (contentTag.indexOf("bildeID:") > -1) {
                console.log("OK" + jsonContent[contentTag]);
                if (!inAnImgDiv) {
                  htmlContent += "<div class='imgSection'>";
                  inAnImgDiv = true;
                }
                htmlContent += "<div class='imgContainer'>";
                htmlContent += "<img src='";
                htmlContent += jsonContent[contentTag];
                htmlContent += "' alt='";
                htmlContent += contentTag;
                htmlContent += "'/>";
                htmlContent += "</div>";
              }
            }
          }
        }
        if (inATitleDiv) {
          htmlContent += "</div>";
        }
        if (inAnImgDiv) {
          htmlContent += "</div>";
        }
        this.setState({
          htmlContent: htmlContent,
        });
        setTimeout(() => {
          //TODO flytt til funksjon og kall på en ordentlig måte. og bruk et bibliotek i stedet for denne hacky greia
          let title: any = document.getElementsByClassName("pageTitle");
          if (title && title[0]) {
            let bodyWidth = document.body.offsetWidth;
            let titleWith = title[0].offsetWidth;
            let widthGoal = bodyWidth * 0.75;
            if (titleWith > widthGoal) {
              let initalFont = parseInt(
                window.getComputedStyle(title[0]).fontSize.slice(0, -1),
              );
              let newFont = initalFont - 1;
              while (titleWith > widthGoal) {
                title[0].style.fontSize = newFont-- + "px";
                titleWith = title[0].offsetWidth;
              }
              title[0].parentElement.style.paddingTop =
                initalFont - newFont + "px";
            }
          }
        }, 1);
      }
    }
  }
  insertExternalLinksInText(
    textToInsertLinksIn: string,
    selfTopicLinks: string[],
    externalTopicsToLink,
  ): string {
    if (externalTopicsToLink) {
      let textToSearchThrough = textToInsertLinksIn.toLowerCase();
      let htmlLinkSuffix = "</a>";
      let htmlLinkPrefixPrefix = "<a href='/s/info/";
      let htmlLinkPrefixSuffix = "'>";
      for (var topicToLink in externalTopicsToLink) {
        if (
          externalTopicsToLink.hasOwnProperty(topicToLink) &&
          externalTopicsToLink[topicToLink].path &&
          externalTopicsToLink[topicToLink].aliases
        ) {
          for (
            let i = 0;
            i < externalTopicsToLink[topicToLink].aliases.length;
            i++
          ) {
            let searchTerm = externalTopicsToLink[topicToLink].aliases[
              i
            ].toLowerCase();
            let textSearchedUntillIndex = 0;
            if (
              !selfTopicLinks ||
              (selfTopicLinks &&
                selfTopicLinks.constructor === Array &&
                !selfTopicLinks.includes(searchTerm))
            ) {
              let indexToReplace = textToSearchThrough.indexOf(
                searchTerm,
                textSearchedUntillIndex,
              );
              while (indexToReplace > -1) {
                console.log("setter inn lenke her:");
                console.log(textToInsertLinksIn.substring(0, indexToReplace));
                console.log("til " + externalTopicsToLink[topicToLink].path);
                let htmlLinkPrefix =
                  htmlLinkPrefixPrefix +
                  externalTopicsToLink[topicToLink].path +
                  htmlLinkPrefixSuffix;
                if (
                  textToSearchThrough.indexOf(
                    htmlLinkPrefixSuffix,
                    indexToReplace - htmlLinkPrefixSuffix.length,
                  ) -
                    indexToReplace +
                    htmlLinkPrefixSuffix.length ===
                    0 ||
                  textToSearchThrough.indexOf(
                    htmlLinkPrefixPrefix,
                    indexToReplace - htmlLinkPrefixPrefix.length,
                  ) -
                    indexToReplace +
                    htmlLinkPrefixPrefix.length ===
                    0 ||
                  this.isIndexWithinHref(
                    indexToReplace,
                    textToSearchThrough,
                    searchTerm,
                  )
                ) {
                  //A link has already been inserted here. Don't insert links on top of previously inserted links
                  textSearchedUntillIndex =
                    indexToReplace + searchTerm.length + htmlLinkSuffix.length;
                } else {
                  //There were no links previously inserted around this searchterm, therefore, we insert a link for this topic
                  textToInsertLinksIn =
                    textToInsertLinksIn.slice(0, indexToReplace) +
                    htmlLinkPrefix +
                    textToInsertLinksIn.slice(
                      indexToReplace,
                      indexToReplace + searchTerm.length,
                    ) +
                    htmlLinkSuffix +
                    textToInsertLinksIn.slice(
                      indexToReplace + searchTerm.length,
                    );
                  textToSearchThrough = textToInsertLinksIn.toLowerCase();
                  textSearchedUntillIndex =
                    indexToReplace +
                    htmlLinkPrefix.length +
                    searchTerm.length +
                    htmlLinkSuffix.length;
                }
                indexToReplace = textToSearchThrough.indexOf(
                  searchTerm,
                  textSearchedUntillIndex,
                );
              }
            }
          }
        }
      }
    }
    return textToInsertLinksIn;
  }

  isIndexWithinHref(index: number, text: string, searchTerm: string): boolean {
    if (index !== null && text !== null) {
      let pre = text.split("href='");
      if (pre && pre.length > 0 && pre[pre.length - 1]) {
        let firstCloserIndex = pre[pre.length - 1].indexOf(">");
        if (firstCloserIndex === -1) {
          return true;
        }
        let termIndex = pre[pre.length - 1].indexOf(searchTerm);
        if (termIndex > -1) {
          return firstCloserIndex > termIndex;
        }
      }
    }
    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.topicId !== this.props.topicId) {
      this.buildInfoPageFromTopic();
    }
  }

  render() {
    return (
      <div className="Information">
        <div className="centerChildrenHorizontaly">
          <h1 className="pageTitle">{this.props.topic}</h1>
        </div>
        {this.state.htmlContent ? (
          <div
            className="flexCol"
            dangerouslySetInnerHTML={{ __html: this.state.htmlContent }}
          />
        ) : (
          <div style={{ textAlign: "center" }}>
            {this.props.topicId ? (
              <p className="subtitle">
                Fant ingen informasjon om {this.props.topic}. Prøv gjerne å
                oppdatere data
              </p>
            ) : (
              <p className="subtitle">
                Informasjon kunne ikke hentes. Prøv gjerne å oppdatere data
              </p>
            )}
            <button
              className="squareButton"
              onClick={() => history.push("/s/data")}
            >
              Oppdater data
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default Information;
