import * as React from "react";
import "../styles/App.css";
import TeethChart from "./tannpleier/TeethChart";
import SystemForNurses from "./sykehjempleier/SystemForNurses";
import strings from "../res/strings";

type MyProps = {};

type MyState = {
  data: any | null;
  nettskjemaPart1Key: string | null;
  patientIdentification: string | null;
  forNurse: boolean | null;
};

class App extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      data: null,
      nettskjemaPart1Key: null,
      patientIdentification: null,
      forNurse: false,
    };
    this.getNettskjema = this.getNettskjema.bind(this);
    this.checkUrlAndContinue = this.checkUrlAndContinue.bind(this);
    this.continueWithBackUpPromt = this.continueWithBackUpPromt.bind(this);
  }

  checkUrlAndContinue(): boolean {
    if (window && window.location && window.location.href) {
      let forNurse = window.location.href.indexOf("/s") !== -1;
      if (forNurse) {
        this.setState({
          forNurse: true,
        });
      }
      let patientId;

      const urlParams = new URLSearchParams(window.location.search);
      const nettskjemaKey = urlParams.get("submissionId");

      if (patientId) {
        this.setState({
          patientIdentification: patientId,
        });
      }
      if (nettskjemaKey) {
        this.setState({
          nettskjemaPart1Key: nettskjemaKey,
        });
      } else if (!forNurse) {
        console.log(
          strings.noKey +
            " (" +
            window.location.href +
            "). " +
            strings.noKeyConsequences,
        );
        if (
          false /*Fjerner dialog intill vi har satt opp koblingsnøkkel*/ &&
          window.confirm(strings.doPart1First)
        ) {
          this.setState({
            data: strings.dataFetchError,
          });
          window.location.href = "https://nettskjema.no/a/129879";
          return false;
        } else {
          this.setState({
            nettskjemaPart1Key:
              strings.noKey +
              " (" +
              window.location.href +
              "). " +
              strings.noKeyConsequences,
          });
        }
      }
    } else {
      console.warn("Url error");
      if (
        window.confirm(
          strings.noKey +
            " " +
            strings.noKeyConsequences +
            " " +
            strings.confirmContinue,
        )
      ) {
        this.setState({
          nettskjemaPart1Key: strings.noKey + " " + strings.noKeyConsequences,
        });
      } else {
        this.setState({
          data: strings.dataFetchError,
          nettskjemaPart1Key: strings.noKey + " " + strings.noKeyConsequences,
        });
        return false;
      }
    }
    return true;
  }

  getNettskjema(): void {
    fetch("/nettskjema")
      .then((res) => {
        if (res) {
          if (res.ok) {
            res.json().then((jsonRes) => {
              if (typeof jsonRes === "undefined" || jsonRes === null) {
                console.error("Could not parse JSON from response:");
                console.log(res);
                this.continueWithBackUpPromt();
              } else {
                this.setState({
                  data: jsonRes,
                });
              }
            });
          } else {
            console.error(res);
            throw new Error(
              "Response was rejected:" + res.status + ": " + res.statusText,
            );
          }
        } else {
          console.error(res);
          throw new Error("Response is null");
        }
      })
      .catch(() => {
        this.continueWithBackUpPromt();
      });
  }

  continueWithBackUpPromt(): void {
    if (window.confirm(strings.fetchErrorContinue)) {
      this.setState({
        data: strings.usingBackup,
      });
    } else {
      this.setState({
        data: strings.dataFetchError,
      });
    }
  }

  componentDidMount() {
    if (this.checkUrlAndContinue()) {
      this.getNettskjema();
    }
  }

  render() {
    return (
      <div
        className={"App " + (this.state.forNurse ? "sykepleier" : "tannpleier")}
      >
        {this.state.data ? (
          this.state.forNurse ? (
            <SystemForNurses toothData={this.state.data} />
          ) : this.state.nettskjemaPart1Key ? (
            this.state.data === strings.dataFetchError ? (
              <h1 key="A0" className="center">
                {strings.dataFetchError}
              </h1>
            ) : (
              <TeethChart
                nettskjema={this.state.data}
                nettskjemaKey={this.state.nettskjemaPart1Key}
                patientIdentification={this.state.patientIdentification}
              />
            )
          ) : (
            <h2 className="subtitle">{strings.unknownErrorMessage}</h2>
          )
        ) : (
          <h1 className="center" key="A1" style={{ animation: `fadeIn 0.6s` }}>
            {strings.currentlyFetchingData}
          </h1>
        )}
      </div>
    );
  }
}

export default App;
