import * as React from "react";
import Tooth from "./Tooth";
import "../styles/TeethRow.css";

type MyProps = {
  id: string;
  toothChartAction: Function | null;
  upper: boolean;
  toothData: any;
  moveForEditor: boolean | null;
  submitStatus: number;
  showIds: boolean;
  shareRefs: Function | null;
  simplified?: boolean;
};

type MyState = {
  shouldShareRefs: boolean;
  refsToShare: any;
  toothObjects: Array<any> | null;
  counter: number;
  toothConnections: Array<Array<string>>;
  hoveredTeeth: Array<string>;
};

const getPosition = (el) => {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
};

const getElementHeight = (el) => {
  const rect = el.getBoundingClientRect();
  return rect.height;
};

const hasState = (tooth, state) =>
  tooth &&
  state &&
  tooth.states &&
  tooth.states[state] &&
  tooth.states[state].answer;

class TeethRow extends React.Component<MyProps, MyState> {
  toothRefs: { [key: string]: any; [key: number]: any };
  containerRef = React.createRef<HTMLDivElement>();

  interval: any = null;

  constructor(props: any) {
    super(props);
    this.toothRefs = {};
    this.state = {
      shouldShareRefs: true,
      refsToShare: null,
      toothObjects: null,
      counter: 0,
      toothConnections: [],
      hoveredTeeth: [],
    };
    this.sendToothToChart = this.sendToothToChart.bind(this);
    this.createtoothObjects = this.createtoothObjects.bind(this);
  }

  sendToothToChart(aTooth: Tooth, add: boolean): void {
    if (this.props.toothChartAction) this.props.toothChartAction(aTooth, add);
  }

  createtoothObjects(): void {
    if (this.props && this.props.toothData) {
      let toothObjects: Array<any> = [];
      let toothComponentRefs: Object = {};
      let toothPlacementExponent = 2.2;
      let tKeys = Object.keys(this.props.toothData);
      if (!tKeys) {
        console.error("No keys in tooth data");
        return;
      }
      let lFrom = 0;
      let lTo: number;
      if (tKeys) {
        lTo = tKeys.length / 2;
      } else {
        console.warn("No keys in toothdata.");
        lTo = 16;
      }
      if (!this.props.upper) {
        lFrom = lTo;
        lTo += lFrom;
      }

      let connectedTeeth: any = [];
      let prevTooth: any = null;
      let toothIndex = {};

      let currentlyConnected = false;

      for (let j = lFrom; j < lTo; j++) {
        if (this.props.toothData && this.props.toothData[tKeys[j]]) {
          const currentTooth = this.props.toothData[tKeys[j]];
          let tempId = this.props.toothData[tKeys[j]].id;

          // Find teeth that are connected
          const isBridge = (tooth) =>
            hasState(tooth, "bridge") || hasState(tooth, "bridgeConnection");
          const isProsthesis = (tooth) => hasState(tooth, "partProsthesis");
          const isMissing = (tooth) => hasState(tooth, "missing");
          const validConnection =
            (isBridge(currentTooth) && isBridge(prevTooth)) ||
            (isMissing(currentTooth) && isBridge(prevTooth)) ||
            (isBridge(currentTooth) && isMissing(prevTooth)) ||
            (isProsthesis(currentTooth) && isProsthesis(prevTooth));

          toothIndex[tempId] = {
            isMissing: isMissing(currentTooth),
          };

          // Disconnect
          if (
            (currentlyConnected && !validConnection) ||
            (currentlyConnected && j === lTo - 1)
          ) {
            currentlyConnected = false;
            // There can be missing teeth inside set of connected bridges, but
            // remove missing teeth from end of the chain.
            const chain = connectedTeeth[connectedTeeth.length - 1];
            // If last tooth is missing, pop it from the connected teeth
            if (toothIndex[chain[chain.length - 1]].isMissing) {
              chain.pop();
            }
          }
          // We are currently in a connected set of teeth
          else if (currentlyConnected && validConnection) {
            connectedTeeth[connectedTeeth.length - 1].push(tempId);
          }
          // When discovering first tooth in chain of connected teeth: connect
          else if (isBridge(currentTooth) || isProsthesis(currentTooth)) {
            currentlyConnected = true;
            connectedTeeth.push([tempId]);
          }

          prevTooth = currentTooth;

          toothComponentRefs[j] = React.createRef();

          const spacing =
            3 -
            Math.pow(Math.abs(j - lFrom - 7.5) * toothPlacementExponent, 2.3) /
              15;

          toothObjects.push({
            id: tempId,
            key: tempId,
            ref: toothComponentRefs[j],
            clickAction: this.sendToothToChart,
            initialStateQuestions: this.props.toothData[tKeys[j]].states,
            upper: this.props.upper,
            spacing: spacing,
            submitStatus: this.props.submitStatus,
            showId: this.props.showIds,
            setRef: (id, ref) => {
              this.toothRefs[id] = ref;
            },
          });
        } else {
          console.log(this.props.toothData);
          console.warn(
            "Inconsistencies in toothdata: Tooth " + j + " is missing",
          );
        }
      }

      this.setState({
        toothConnections: connectedTeeth,
      });

      if (toothObjects) {
        this.setState({
          refsToShare: toothComponentRefs,
          toothObjects: toothObjects,
        });
      }
    }
  }

  componentDidUpdate() {
    if (this.state.shouldShareRefs) {
      if (this.props.shareRefs) {
        this.props.shareRefs(this.state.refsToShare);
      }
      this.setState({ shouldShareRefs: false });
    }
    if (this.state.toothObjects && this.state.toothObjects.length > 0) {
      for (var tc in this.state.toothObjects) {
        if (
          this.state.toothObjects.hasOwnProperty(tc) &&
          this.state.toothObjects[tc].submitStatus !== this.props.submitStatus
        ) {
          this.createtoothObjects();
          break;
        }
      }
    }
  }

  componentDidMount() {
    this.createtoothObjects();

    if (this.props.simplified) {
      // Update state to re-render connection between teeth to match up with
      // transitions and window resizes
      this.interval = setInterval(() => {
        this.setState((prev) => ({ counter: prev.counter + 1 }));
      }, 300);
    }
  }

  componentWillUnmount() {
    if (this.interval !== null) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  onToothHover(tooth) {
    let connections = this.state.toothConnections;

    for (let group of connections) {
      if (group.indexOf(tooth) !== -1) {
        this.setState({ hoveredTeeth: group });
        return;
      }
    }
    this.setState({ hoveredTeeth: [tooth] });
  }

  onToothHoverLeave() {
    this.setState({ hoveredTeeth: [] });
  }

  render() {
    let offset = { top: 0, left: 0 };
    let containerRect: any = null;
    if (this.containerRef && this.containerRef.current) {
      offset = getPosition(this.containerRef.current);
      containerRect = this.containerRef.current.getBoundingClientRect();
    }

    let teethContainerHeight = 0;
    if (this.containerRef && this.containerRef.current) {
      teethContainerHeight = getElementHeight(this.containerRef.current);
    }

    return (
      <>
        {/* 
          Draw lines between bridged teeths
        */}
        {this.props.simplified && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              pointerEvents: "none",
            }}
            ref={this.containerRef}
          >
            {containerRect && (
              <svg
                width="100%"
                height="100%"
                viewBox={`0 0 ${containerRect.width} ${containerRect.height}`}
                style={{ pointerEvents: "none" }}
              >
                {this.state.toothConnections
                  .filter((connections) => connections.length > 1)
                  .map((connections) => {
                    let path = "";
                    for (let i = 0; i < connections.length; i++) {
                      const connection = connections[i];
                      const tRef = this.toothRefs[connection];
                      if (tRef) {
                        const pos = getPosition(tRef);
                        const rect = tRef.getBoundingClientRect();
                        const x = pos.left - offset.left + rect.width / 2;
                        const y = pos.top - offset.top + rect.height / 2;
                        if (i === 0) {
                          path += `M ${x} ${y}`;
                        } else {
                          path += ` L ${x} ${y}`;
                        }
                      }
                    }
                    return (
                      <path
                        d={path}
                        stroke="black"
                        fill="transparent"
                        strokeWidth="5"
                      />
                    );
                  })}
              </svg>
            )}
          </div>
        )}
        <div
          className={
            "TeethRow " +
            (this.props.upper ? "upper" : "lower") +
            (this.props.moveForEditor === null
              ? " immovable"
              : this.props.moveForEditor
              ? "Moved"
              : "")
          }
        >
          {this.state.toothObjects?.map((t) => (
            <Tooth
              id={t.id}
              key={t.key}
              ref={t.ref}
              clickAction={t.clickAction}
              initialStateQuestions={t.initialStateQuestions}
              upper={t.upper}
              spacing={t.spacing}
              containerHeight={teethContainerHeight}
              submitStatus={t.submitStatus}
              showId={t.showId}
              simplified={this.props.simplified}
              setRef={t.setRef}
              onToothHover={this.onToothHover.bind(this)}
              onToothHoverLeave={this.onToothHoverLeave.bind(this)}
              hovered={this.state.hoveredTeeth.indexOf(t.id) !== -1}
            />
          ))}
        </div>
      </>
    );
  }
}

export default TeethRow;
