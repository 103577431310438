import * as React from 'react'
import '../../styles/Tips.css'
type MyProps = {
  text: string,
  onClickAction: Function
}

type MyState = {
}

class Tips extends React.Component<MyProps, MyState> {

  render() {
    return (
        <b onClick={()=>this.props.onClickAction} className="Tips">{this.props.text}</b>
    )
  }
}

export default Tips