import React, { FC } from "react";
import { teethHaveState, countWhiteTeeth } from "../../utils";
import colors from "../../res/colors";

/**
 * Legend for teeth states
 * Shows only relevant keys for current set of teeth
 */
const ColorChart: FC<{ toothData: any }> = ({ toothData }) => {
  const colorChartItems: any = [];
  if (toothData) {
    if (
      teethHaveState(toothData, "partProsthesis") ||
      teethHaveState(toothData, "wholeProsthesis")
    ) {
      colorChartItems.push({
        color: colors.tooth.simplified.removableProsthesis,
        label: "Protese som kan tas ut",
      });
    }

    if (
      teethHaveState(toothData, "bridge") ||
      teethHaveState(toothData, "bridgeConnection") ||
      teethHaveState(toothData, "implant") ||
      teethHaveState(toothData, "crown")
    ) {
      colorChartItems.push({
        color: colors.tooth.simplified.permanentProsthesis,
        label: "Fastsittende tannerstatning",
      });
    }

    // TODO: Impantat && bro korrekt? Ikke bare implantat, eller imp || bro?
    if (
      teethHaveState(toothData, "implant") &&
      teethHaveState(toothData, "bridge")
    ) {
      colorChartItems.push({
        color: "#565350",
        label: "Implantat",
      });
    }

    if (countWhiteTeeth(toothData) > 0) {
      colorChartItems.push({
        color: colors.tooth.fill.default,
        label: "Egne tenner",
      });
    }

    if (teethHaveState(toothData, "missing")) {
      colorChartItems.push({
        color: colors.tooth.fill.missing,
        label: "Tenner som mangler",
        missing: true,
      });
    }

    if (teethHaveState(toothData, "rest")) {
      colorChartItems.push({
        color: colors.tooth.other.rests,
        label: "Rotrest",
      });
    }
  }

  return (
    <div className="colorChart">
      {colorChartItems.map((item) => (
        <div className="colorChartRow">
          <div
            className="colorChartSwatch"
            style={{
              backgroundColor: item.color,
              borderColor: item.missing ? item.color : "#000",
            }}
          />
          <div>{item.label}</div>
        </div>
      ))}
    </div>
  );
};

export default ColorChart;
