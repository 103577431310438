import * as React from "react";
import "../styles/Tooth.css";
import "../res/colors";
import colors from "../res/colors";
import strings from "../res/strings";

type MyProps = {
  id: number;
  clickAction: Function;
  upper: boolean;
  spacing: number;
  containerHeight: number;
  initialStateQuestions: any;
  submitStatus: number;
  showId: boolean;
  simplified?: boolean;
  setRef: any;
  onToothHover: (tooth) => void;
  onToothHoverLeave: () => void;
  hovered: boolean;
};

type MyState = {
  highlighted: boolean;
  hovered: boolean;
  toothStateQuestions: any;
};

class Tooth extends React.Component<MyProps, MyState> {
  public toothElement: any;
  toothRef: any;

  constructor(props: any) {
    super(props);
    this.toothRef = React.createRef();
    this.state = {
      highlighted: false,
      toothStateQuestions: this.props.initialStateQuestions,
      hovered: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.setHighlighting = this.setHighlighting.bind(this);
    this.toothMouseLeave = this.toothMouseLeave.bind(this);
    this.toothMouseEnter = this.toothMouseEnter.bind(this);
    this.getStatesString = this.getStatesString.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.toothElement = React.createRef();
    this.focusMe = this.focusMe.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleClick(event: any | null): void {
    this.props.clickAction(this, event);
  }

  handleKeyDown(keyEvent: any): void {
    if (keyEvent.keyCode === 13 || keyEvent.keyCode === 32) {
      this.handleClick(keyEvent);
    }
  }

  setHighlighting(onOff: boolean): void {
    this.setState(() => {
      return { highlighted: onOff };
    });
  }

  toothMouseEnter(event: any): void {
    // Ignore hover if simplified nurse view and hovering teeth with no
    // onClick events (missing and normal teeth). See
    // src/components/sykehjempleier/Room.tsx: toothClickHandler() for
    // onClick logic
    if (this.props.simplified) {
      const questions = this.state.toothStateQuestions || {};
      const numStates = Object.keys(questions).length;

      if (numStates === 0) {
        return;
      } else if (numStates === 1 && questions.coating?.answer) {
        return;
      } else if (questions.missing?.answer) {
        return;
      }
    }

    if (!this.state.highlighted) {
      this.handleClick(event);
    }
    this.props.onToothHover(this.props.id);
    this.setState({
      hovered: true,
    });
  }

  toothMouseLeave(event: any): void {
    this.setState({
      hovered: false,
    });
    this.props.onToothHoverLeave();
    if (!this.state.highlighted) {
      this.handleClick(event);
    }
  }

  toothTouch(event) {
    //TODO, ikke optimalt, men ok midlertidig?
    this.handleClick(event);
  }

  focusMe(): void {
    if (this.toothElement.current) {
      this.toothElement.current.focus();
    }
    this.handleFocus();
  }

  handleFocus(): void {
    this.setState({
      hovered: true,
    });
  }

  handleBlur(): void {
    this.setState({
      hovered: false,
    });
  }

  getStatesString(): string {
    let tempS = "";
    if (this.state.toothStateQuestions) {
      for (var key in this.state.toothStateQuestions) {
        tempS += " " + this.state.toothStateQuestions[key].question + ": ";
        if (typeof this.state.toothStateQuestions[key].answer === "boolean") {
          tempS += this.state.toothStateQuestions[key].answer
            ? strings.affermative
            : strings.negative;
          tempS += ".";
        }
      }
    }
    return tempS;
  }

  getFill(): string {
    if (this.state.toothStateQuestions) {
      // Forenklet visning for sykehjemspleiere
      if (this.props.simplified) {
        const questions = this.state.toothStateQuestions;

        if (
          (questions.wholeProsthesis && questions.wholeProsthesis.answer) ||
          (questions.partProsthesis && questions.partProsthesis.answer)
        ) {
          return colors.tooth.simplified.removableProsthesis;
        }

        if (
          (questions.bridge && questions.bridge.answer) ||
          (questions.bridgeConnection && questions.bridgeConnection.answer) ||
          (questions.implant && questions.implant.answer) ||
          (questions.crown && questions.crown.answer)
        ) {
          return colors.tooth.simplified.permanentProsthesis;
        }
      }

      if (
        this.state.toothStateQuestions.wholeProsthesis &&
        this.state.toothStateQuestions.wholeProsthesis.answer
      )
        return colors.tooth.fill.wholeProsthesis;
      if (
        this.state.toothStateQuestions.crown &&
        this.state.toothStateQuestions.crown.answer
      )
        return colors.tooth.fill.crown;
      if (
        this.state.toothStateQuestions.missing &&
        this.state.toothStateQuestions.missing.answer
      )
        return colors.tooth.fill.missing;
      if (
        this.state.toothStateQuestions.rest &&
        this.state.toothStateQuestions.rest.answer
      )
        return colors.tooth.fill.missing;
    }
    return colors.tooth.fill.default;
  }

  getPathForToothType(): string {
    if (this.props.id) {
      switch (this.props.id % 10) {
        case 1:
          return "M318.083 152H694.307C717.209 152 738.729 162.786 752.465 181.111C761.9 193.699 767 209.004 767 224.736V268.765C767 274.514 761.731 405.291 759.096 469.961L730.644 633.433L700.41 733.868C693.279 757.558 680.379 779.107 662.866 796.582C638.223 821.172 605.768 836.389 571.103 839.605L515.492 844.764C485.39 847.556 455.038 843.957 426.424 834.202C373.197 816.054 329.22 777.737 303.957 727.494L298.8 717.238C286.454 692.684 278.389 666.218 274.818 638.968C263.962 556.132 248.07 440.747 243.791 432.237C237.468 419.662 232.726 249.005 234.306 240.023C240.902 202.545 268.911 171.756 303.933 156.869C310.878 153.917 316.143 152 318.083 152Z";
        case 2:
          return "M677.693 139.597L295.027 133.535C273.75 133.198 253.545 142.848 240.433 159.607C231.081 171.56 226 186.3 226 201.477V286.186L254.84 527.679L272.464 630.404L302.906 734.931C317.326 792.601 336.553 830.447 357.382 843.062C407.521 873.43 485.559 868.293 507.99 862.886C599.787 840.76 674.776 775.147 705.357 685.811C717.751 649.603 727.911 621.002 730.698 615.986C738.709 601.569 761.14 363.68 770.754 333.043C776.383 315.102 775.488 267.74 773.347 229.27C772.025 205.517 761.291 183.394 744.125 166.925C726.227 149.753 702.494 139.99 677.693 139.597Z";
        case 3:
          return "M751.269 329.926C730.02 267.549 669.271 218.109 615.719 169.309C584.669 141.014 550.547 123 508.539 123C488.194 123 462.226 127.205 443.583 135.351L383.41 161.643C354.48 174.284 328.317 192.49 306.41 215.225C263.163 260.108 239 320.009 239 382.337V391.444L252.04 617.012L257.268 657.336C272.688 776.248 369.107 868.215 488.615 878L539.301 876.139C628.862 872.849 704.712 809.074 723.327 721.407L758.72 466.012C758.72 424.379 769.785 384.282 751.269 329.926Z";
        case 4:
          return "M456.958 156.493L466.018 155.81C499.6 153.275 533.344 153.871 566.815 157.59C630.462 164.661 692.378 182.949 749.656 211.586L772.942 223.228C842.689 258.099 834 363.117 834 441.096V487.956C834 531.192 830.568 574.36 823.737 617.053L814.171 676.834C805.734 729.561 802.358 790.278 750.554 803.228C747.437 804.007 712.183 811.033 668.309 819.71C573.07 838.544 475.752 840.844 378.939 833.591C363.513 832.435 349.885 831.719 339.432 831.719C321.115 831.719 241.85 807.85 229.528 746.246C207.14 634.316 194.93 605.824 172.541 483.719C159.481 412.491 172.541 306.667 180.682 268C201.795 167.723 354.772 164.205 456.958 156.493Z";
        case 5:
          return "M324.056 189.383L452.983 161.752C489.33 153.963 526.65 151.738 563.665 155.156C610.712 159.499 656.478 172.882 698.452 194.572L729.704 210.721C744.647 218.442 758.594 227.956 771.236 239.051C819.629 281.524 845.51 344.091 841.263 408.339L834.69 507.759C833.139 531.22 829.568 554.505 824.017 577.353L797.925 684.747C787.299 728.483 762.372 767.417 727.099 795.372C695.062 820.764 656.075 835.838 615.289 838.604L494.715 846.779C463.099 848.923 431.333 848.545 399.644 848.581L399.481 848.581C364.729 848.62 328.184 848.661 288.854 834.358C245.843 818.715 214.347 794.715 191.101 736.592C165.785 673.294 163.403 622.476 159.207 572.491C155.908 533.198 152 493.85 152 454.419V343.877C152 326.914 155.51 310.133 162.308 294.592C173.361 269.324 192.588 248.505 216.898 235.48L257.121 213.93C278.172 202.651 300.704 194.388 324.056 189.383Z";
        case 6:
          return "M518.54 194.04L543.299 198.167C569.38 202.514 595.977 202.793 622.143 198.995L688.96 189.295C722.68 185.294 764.401 179.007 798.693 179.007C807.31 179.007 814.685 180.259 821.196 182.31C861.21 194.911 888.39 235.948 907.664 273.211C921.594 300.144 927.489 330.513 924.648 360.702L919.172 418.893C916.588 446.343 910.836 473.401 902.029 499.528L882.637 557.062C870.144 594.124 853.804 629.776 833.885 663.435L815.157 695.078C781.807 751.43 741.416 810.261 680.387 833.995C671.973 837.268 641.426 843.28 606.035 845.308C590.562 846.195 575.139 843.606 560.37 838.906L549.592 835.477C522.248 826.776 492.693 828.154 466.277 839.361C455.901 843.764 444.956 846.683 433.765 848.034L379.906 854.534C360.808 856.839 341.53 857.257 322.35 855.781C241.239 849.542 162.455 812.488 118.967 743.737C116.049 739.124 113.928 735.385 112.864 732.832C106.006 716.372 81.4305 504.215 70 400.194V366.177C70 305.717 98.1632 251.174 136.868 204.727C139.676 201.358 153.496 193.277 171.273 183.73C223.387 155.745 282.735 144.738 341.794 148.05L398.498 151.229C422.542 152.578 445.806 160.246 465.939 173.459C481.851 183.901 499.766 190.911 518.54 194.04Z";
        case 7:
          return "M230.829 168.408C302.025 140.61 408.625 176.882 418.37 180.572C439.365 188.522 458.508 195.184 475.494 200.608C524.245 216.174 575.758 213.319 625.782 202.521L678.976 191.039C710.993 184.128 744.052 183.551 776.29 189.341L801.475 193.864C841.245 201.008 877.402 221.47 904 251.889C932.366 284.329 948 325.959 948 369.052V431.814C948 478.156 938.667 524.024 920.557 566.681L877.512 668.069C849.849 733.227 809.429 797.915 746.567 830.459C700.097 854.517 649.679 837.625 606.237 831.688C599.102 830.713 591.995 829.517 585.042 827.643C562.38 821.538 542.872 806.911 521.358 797.531C509.108 792.191 494.984 788.983 477.413 785.284C472.657 784.283 440.276 792.291 395.643 805.806C369.123 813.836 353.667 813.739 326.336 818.295C319.484 819.437 251.669 830.459 239.513 830.459C166.845 830.459 115.699 775.092 108.834 702.748C107.785 691.688 106.295 679.862 104.067 667.123C102.2 656.451 84.6921 597.345 71.0601 538.176C57.6606 480.016 45.3066 419.552 55.5806 360.759C58.62 343.366 63.9421 320.239 72.8079 288.307C90.1749 225.755 213.632 175.122 230.829 168.408Z";
        case 8:
          return "M189.178 819.774C157.074 796.015 123.905 737.683 96.6429 675.321C70.7169 616.014 56.6489 552.295 47.6641 488.195C45.5086 472.817 44 460.858 44 457.164L44.0004 352.563C44.0004 332.819 47.3716 313.223 53.9685 294.613C67.0064 257.835 91.6305 225.686 126.359 207.893C141.469 200.151 156.983 193.031 173.245 186.532C202.854 174.7 238.862 176.225 269.227 178.597C295.832 180.675 322.219 186.642 346.75 197.145C371.281 207.649 397.689 213.065 424.374 213.065H445.896H462.282C500.778 213.065 527.993 186.314 564.518 174.151C581.701 168.428 601.009 165.206 620.086 163.368C680.652 157.534 753.774 154.031 799.99 193.608C812.383 204.221 842.481 227.216 870.808 255.517C910.119 294.791 923.922 306.813 941.627 354.571C963.849 414.515 955.907 463.469 946.938 503.153C934.545 557.987 932.775 550.912 927.463 570.369C922.152 589.826 912.878 635.816 904.447 680.036C893.745 736.168 852.029 787.005 797.697 804.705C793.666 806.018 789.923 807.208 786.598 808.245C776.685 811.338 766.479 813.052 756.119 813.765C733.862 815.297 693.131 818.117 670.745 819.774C583.992 826.194 582.459 823.91 564.518 819.774C518.485 809.161 491.764 796.779 465.372 796.779C395.602 796.779 347.133 842.276 277.437 839.088C239.764 837.365 206.789 832.807 189.178 819.774Z";
      }
    }
    console.error("Fant ikke path for tanntypen " + this.props.id);
    return "M324.056 189.383L452.983 161.752C489.33 153.963 526.65 151.738 563.665 155.156C610.712 159.499 656.478 172.882 698.452 194.572L729.704 210.721C744.647 218.442 758.594 227.956 771.236 239.051C819.629 281.524 845.51 344.091 841.263 408.339L834.69 507.759C833.139 531.22 829.568 554.505 824.017 577.353L797.925 684.747C787.299 728.483 762.372 767.417 727.099 795.372C695.062 820.764 656.075 835.838 615.289 838.604L494.715 846.779C463.099 848.923 431.333 848.545 399.644 848.581L399.481 848.581C364.729 848.62 328.184 848.661 288.854 834.358C245.843 818.715 214.347 794.715 191.101 736.592C165.785 673.294 163.403 622.476 159.207 572.491C155.908 533.198 152 493.85 152 454.419V343.877C152 326.914 155.51 310.133 162.308 294.592C173.361 269.324 192.588 248.505 216.898 235.48L257.121 213.93C278.172 202.651 300.704 194.388 324.056 189.383Z";
  }

  transformedClassName(): string {
    if (this.props.id)
      switch (this.props.id[0]) {
        case "1":
          return " upsideDown";
        case "2":
          return " upsideDownMirrored";
        case "3":
          return " mirrored";
      }
    return "";
  }

  render() {
    let simplified = this.props.simplified;
    let strokeColor = colors.tooth.stroke.notHighlighted;
    let strokeWidth = "35";
    let strokeWidthLight = "17";
    const toothIsMissing =
      this.state.toothStateQuestions &&
      this.state.toothStateQuestions.missing &&
      this.state.toothStateQuestions.missing.answer;

    if (this.state.hovered || (this.props.hovered && !toothIsMissing)) {
      strokeWidth = "45";
      strokeColor = colors.tooth.stroke.hovered;
    } else if (this.state.highlighted) {
      strokeColor = colors.tooth.stroke.highlighted;
      strokeWidth = "55";
    } else if (
      toothIsMissing ||
      (this.state.toothStateQuestions &&
        this.state.toothStateQuestions.rest &&
        this.state.toothStateQuestions.rest.answer)
    ) {
      strokeColor = colors.tooth.stroke.missing;
      strokeWidth = "0";
    }

    this.props.setRef({ id: this.props.id, ref: this.toothRef });

    let buttonStyle = simplified
      ? {
          // this.props.spacing varierer fra ca. 2 til -40. Her mapper vi
          // bare det til å dekke ca. hele container-elementet, og krymper
          // litt til for å få litt mer padding.
          marginTop:
            (this.props.spacing / 40) * this.props.containerHeight * 0.95 +
            "px",
        }
      : {
          marginTop: this.props.spacing + "vh",
        };

    return (
      <button
        className={
          "Tooth" +
          this.transformedClassName() +
          (this.props.submitStatus >= 1
            ? " submitted "
            : this.props.submitStatus > 0
            ? " submitting"
            : " defaultPlacement")
        }
        key={this.props.id + "T1"}
        onMouseDown={(event) => this.handleClick(event)}
        onKeyDown={this.handleKeyDown}
        onTouchMove={(event) => this.toothTouch(event)}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        style={buttonStyle}
        aria-haspopup="true"
        aria-controls="ToothEditorMenu"
      >
        {this.props.showId && (
          <h5 className={this.transformedClassName()}>
            {this.state.highlighted ? <u>{this.props.id}</u> : this.props.id}
          </h5>
        )}
        <svg
          onMouseEnter={this.toothMouseEnter}
          onMouseLeave={this.toothMouseLeave}
          viewBox="0 0 1000 1000"
          xmlns="http://www.w3.org/2000/svg"
          key={this.props.id + "T1"}
          ref={(ref) => this.props.setRef(this.props.id, ref)}
        >
          <title>{strings.toothAltTitle}</title>
          <desc>
            {strings.toothAltDesc +
              " " +
              this.props.id +
              ". " +
              (this.state.highlighted ? " Valgt" : "Ikke valgt") +
              " ." +
              strings.toothAltDescStates +
              this.getStatesString()}
          </desc>
          <path
            d={this.getPathForToothType()}
            fill={this.getFill()}
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            key={this.props.id + "T1"}
          />

          {this.state.hovered && (
            <ellipse
              cx="500"
              cy="21"
              rx="360"
              ry="21"
              fill={colors.tooth.stroke.hovered}
            />
          )}
          {!simplified &&
            this.state.toothStateQuestions &&
            this.state.toothStateQuestions.partProsthesis &&
            this.state.toothStateQuestions.partProsthesis.answer && (
              <g>
                <mask id="path-1-inside-1" fill="white">
                  <rect x="268" y="581" width="465" height="407" rx="16" />
                </mask>
                <rect
                  x="268"
                  y="581"
                  width="465"
                  height="407"
                  rx="16"
                  fill={colors.tooth.other.partProthesis}
                  stroke="black"
                  strokeWidth={strokeWidthLight}
                  mask="url(#path-1-inside-1)"
                />
              </g>
            )}
          {this.state.toothStateQuestions &&
            this.state.toothStateQuestions.rest &&
            this.state.toothStateQuestions.rest.answer && (
              <path
                d="M482.938 974.479L323.524 594.151C318.415 581.961 327.368 568.5 340.586 568.5H659.414C672.632 568.5 681.585 581.961 676.476 594.151L517.062 974.479C510.72 989.611 489.281 989.611 482.938 974.479Z"
                fill={colors.tooth.other.rests}
                stroke="black"
                strokeWidth={strokeWidthLight}
              />
            )}
          {!simplified &&
            this.state.toothStateQuestions &&
            this.state.toothStateQuestions.bridge &&
            this.state.toothStateQuestions.bridge.answer && (
              <g>
                <rect
                  y="312"
                  width="87"
                  height="376"
                  rx="16"
                  fill={colors.tooth.other.bridge}
                />
                <rect
                  x="913"
                  y="312"
                  width="87"
                  height="376"
                  rx="16"
                  fill={colors.tooth.other.bridge}
                />
                <rect
                  x="43"
                  y="434"
                  width="913"
                  height="133"
                  rx="16"
                  fill={colors.tooth.other.bridge}
                />
              </g>
            )}
          {!simplified &&
            this.state.toothStateQuestions &&
            this.state.toothStateQuestions.bridgeConnection &&
            this.state.toothStateQuestions.bridgeConnection.answer && (
              <g>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16 312C7.16344 312 0 319.163 0 328V451V549V672C0 680.837 7.16344 688 16 688H71C79.8366 688 87 680.837 87 672V565H241C249.837 565 257 557.837 257 549V451C257 442.163 249.837 435 241 435H87V328C87 319.163 79.8366 312 71 312H16ZM929 312C920.163 312 913 319.163 913 328V435H759C750.163 435 743 442.163 743 451V549C743 557.837 750.163 565 759 565H913V672C913 680.837 920.163 688 929 688H984C992.837 688 1000 680.837 1000 672V549V451V328C1000 319.163 992.837 312 984 312H929Z"
                  fill="black"
                />
                <path
                  d="M87 565V559H81V565H87ZM87 435H81V441H87V435ZM913 435V441H919V435H913ZM913 565H919V559H913V565ZM6 328C6 322.477 10.4772 318 16 318V306C3.84974 306 -6 315.85 -6 328H6ZM6 451V328H-6V451H6ZM6 549V451H-6V549H6ZM6 672V549H-6V672H6ZM16 682C10.4772 682 6 677.523 6 672H-6C-6 684.15 3.84973 694 16 694V682ZM71 682H16V694H71V682ZM81 672C81 677.523 76.5228 682 71 682V694C83.1503 694 93 684.15 93 672H81ZM81 565V672H93V565H81ZM241 559H87V571H241V559ZM251 549C251 554.523 246.523 559 241 559V571C253.15 571 263 561.15 263 549H251ZM251 451V549H263V451H251ZM241 441C246.523 441 251 445.477 251 451H263C263 438.85 253.15 429 241 429V441ZM87 441H241V429H87V441ZM81 328V435H93V328H81ZM71 318C76.5228 318 81 322.477 81 328H93C93 315.85 83.1503 306 71 306V318ZM16 318H71V306H16V318ZM919 328C919 322.477 923.477 318 929 318V306C916.85 306 907 315.85 907 328H919ZM919 435V328H907V435H919ZM759 441H913V429H759V441ZM749 451C749 445.477 753.477 441 759 441V429C746.85 429 737 438.85 737 451H749ZM749 549V451H737V549H749ZM759 559C753.477 559 749 554.523 749 549H737C737 561.15 746.85 571 759 571V559ZM913 559H759V571H913V559ZM919 672V565H907V672H919ZM929 682C923.477 682 919 677.523 919 672H907C907 684.15 916.85 694 929 694V682ZM984 682H929V694H984V682ZM994 672C994 677.523 989.523 682 984 682V694C996.15 694 1006 684.15 1006 672H994ZM994 549V672H1006V549H994ZM994 451V549H1006V451H994ZM994 328V451H1006V328H994ZM984 318C989.523 318 994 322.477 994 328H1006C1006 315.85 996.15 306 984 306V318ZM929 318H984V306H929V318Z"
                  fill="black"
                  mask="url(#path-3-inside-1)"
                />
              </g>
            )}
          {!simplified &&
            this.state.toothStateQuestions &&
            this.state.toothStateQuestions.coating &&
            this.state.toothStateQuestions.coating.answer && (
              <path
                d="M825.5 798C825.5 807.952 817.735 818.353 801.343 828.499C785.142 838.527 761.465 847.685 731.898 855.425C672.816 870.893 591.003 880.5 500.5 880.5C409.997 880.5 328.184 870.893 269.102 855.425C239.535 847.685 215.858 838.527 199.657 828.499C183.265 818.353 175.5 807.952 175.5 798C175.5 788.048 183.265 777.647 199.657 767.501C215.858 757.473 239.535 748.315 269.102 740.575C328.184 725.107 409.997 715.5 500.5 715.5C591.003 715.5 672.816 725.107 731.898 740.575C761.465 748.315 785.142 757.473 801.343 767.501C817.735 777.647 825.5 788.048 825.5 798Z"
                fill={colors.tooth.other.coating}
                stroke="black"
                strokeWidth={strokeWidthLight}
              />
            )}
          {this.state.toothStateQuestions &&
            this.state.toothStateQuestions.implant &&
            this.state.toothStateQuestions.implant.answer && (
              <g>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M435 483.671V451.966L551.057 400H564.908V425.502L435 483.671ZM435 400H503.263L435 430.566V400ZM564.908 446.902L435 505.071V536.775L564.908 478.607V446.902ZM435 589.877V558.175L564.908 500.007V531.709L435 589.877ZM564.908 553.109L435 611.278V642.514L564.908 584.346V553.109ZM435 749.191V717.487L564.908 659.318V691.023L435 749.191ZM435 770.591V802.293L564.908 744.125V712.423L435 770.591ZM435 855.398V823.693L564.908 765.525V797.229L435 855.398ZM435 876.798V884.594L445.779 903.676L564.908 850.334V818.63L435 876.798ZM469.721 946.06L455.427 920.756L564.908 871.734V884.589H564.905L550.652 909.822L469.721 946.06ZM479.369 963.14L493.663 988.444L510.498 980.906L534.47 938.468L479.369 963.14ZM564.908 637.918L435 696.086V663.914L564.908 605.746V637.918Z"
                  fill={colors.tooth.other.implant}
                />
              </g>
            )}
        </svg>
      </button>
    );
  }
}

export default Tooth;
