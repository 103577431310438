import * as React from "react";
import { RouteProps } from "react-router";
import strings from "../../res/strings";
import "../../styles/RoomSelect.css";
import history from "./history";
type MyProps = {};

type MyState = {
  data: any | null;
  floorNum: string | null;
};

class RoomSelect extends React.Component<MyProps & RouteProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      data: null,
      floorNum: null,
    };
    this.getFloor = this.getFloor.bind(this);
    this.createRButtons = this.createRButtons.bind(this);
  }

  componentDidMount() {
    let lData = localStorage.getItem(strings.dataKey);
    if (lData) {
      lData = JSON.parse(lData);
    }
    let floor = this.getFloor();
    if (floor) {
      localStorage.setItem(strings.selectedFloorKey, floor);
    } else {
      floor = localStorage.getItem(strings.selectedFloorKey);
    }
    this.setState({ floorNum: floor, data: lData });
  }

  getFloor(): string | null {
    if (this.props.match)
      if (this.props.match.params.floorNumber !== strings.nullStringKey)
        return this.props.match.params.floorNumber;
    return null;
  }

  createRButtons(): Array<any> | null {
    let data: null | string = localStorage.getItem(strings.dataKey);
    if (data && this.state.floorNum) {
      let dataAsObject = JSON.parse(data);
      if (dataAsObject && dataAsObject.constructor === Object) {
        let roomList = new Array();
        for (var patient in dataAsObject) {
          if (
            dataAsObject.hasOwnProperty(patient) &&
            dataAsObject[patient]["floor"] &&
            dataAsObject[patient]["room"]
          ) {
            if (dataAsObject[patient]["floor"] === this.state.floorNum) {
              roomList.push({
                roomNumber: dataAsObject[patient]["room"],
                patientFirstName: dataAsObject[patient]["patientFirstName"],
              });
            }
          }
        }
        roomList = [...new Set(roomList)];
        roomList.sort((a, b) => parseInt(a) - parseInt(b));
        if (roomList) {
          roomList = roomList
            .sort((a, b) => {
              if (a.roomNumber < b.roomNumber) {
                return -1;
              }
              if (a.roomNumber > b.roomNumber) {
                return 1;
              }
              // a must be equal to b
              return 0;
            })
            .map((r) => (
              <button
                className="squareButton room"
                key={"rb" + r}
                onClick={() =>
                  history.push(
                    "/s/etasje/" + this.state.floorNum + "/rom/" + r.roomNumber,
                  )
                }
              >
                {r.roomNumber} – {r.patientFirstName}
              </button>
            ));
          return roomList;
        }
      }
    }
    return null;
  }

  render() {
    let rButtons = this.createRButtons();
    return (
      <div className="">
        {!this.state.data ? (
          <div>
            <div className="centerChildrenHorizontaly">
              <h1 className="pageTitle">Fant ingen data å vise frem</h1>
            </div>
            <button
              className="squareButton"
              onClick={() => history.push("/s/data")}
            >
              Adminstrer Data
            </button>
          </div>
        ) : !this.state.floorNum ? (
          <div>
            <div className="centerChildrenHorizontaly">
              <h1 className="pageTitle">Romoversikt</h1>
            </div>
            <h2 className="subtitle">Etasje er ikke valgt</h2>
            <button
              className="squareButton"
              onClick={() => history.push("/s/etasje")}
            >
              Etasjeoversikt
            </button>
          </div>
        ) : (
          <div>
            <div className="centerChildrenHorizontaly">
              <h1 className="pageTitle">Romoversikt</h1>
            </div>
            <h2 className="subtitle">{this.state.floorNum}. etasje</h2>
            <div className="rooms">
              {rButtons ? (
                rButtons
              ) : (
                <div>
                  <h2 className="subtitle">
                    Ingen rom i {this.state.floorNum}. etasje har blitt ført inn
                    i systemet i nyligste version av data
                    {this.state.data &&
                      this.state.data.updatedTime &&
                      " (" + this.state.data.updatedTime + ")"}
                  </h2>
                  <button
                    className="squareButton"
                    onClick={() => history.push("/s/data")}
                  >
                    Adminstrer data{" "}
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default RoomSelect;
