import * as React from 'react'
import '../../styles/BottomBar.css'
import strings from '../../res/strings'
import Tips from "./Tips"
type MyProps = {
  submitRequested: Function
  printRequested: Function
  afterPrint: Function
  unselect: Function
  showTips: boolean
  toggleTips: Function
}

type MyState = {
}

class BottomBar extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props)

    this.printRequested = this.printRequested.bind(this)
    this.submitRequested = this.submitRequested.bind(this)
    this.afterPrint = this.afterPrint.bind(this)
    this.unSelect = this.unSelect.bind(this)
  }
  printRequested(): void {
    this.props.printRequested()
  }

  submitRequested(): void {
    this.props.submitRequested()
  }

  afterPrint(): void {
    this.props.afterPrint()
  }

  unSelect(): void {
    this.props.unselect()
  }

  componentDidMount() {
    if (window.matchMedia) {
      var mediaQueryList = window.matchMedia('print')
      mediaQueryList.addListener(this.afterPrint)
    }
    window.addEventListener("afterprint", this.afterPrint, true)//TODO fiks..Dobbelt opp intill videre pga firefox/chrome bugs(?)
  }

  componentWillUnmount() {
    if (window.matchMedia) {
      var mediaQueryList = window.matchMedia('print')
      mediaQueryList.removeListener(this.afterPrint)
    }
    window.removeEventListener("afterprint", this.afterPrint, true)//TODO fiks..Dobbelt opp intill videre pga firefox/chrome bugs(?)
  }

  render() {
    return (
      <div className="fullWidth">
        {this.props.showTips &&
          <div onClick={() => this.props.toggleTips} className="overlay">
            <Tips
              onClickAction={this.props.toggleTips}
              text={strings.tipsText}></Tips>
          </div>
        }
        <div className="BottomBar">
          {this.props.showTips ?
            <button className={"bButton centerTipsButton"} onClick={() => this.props.toggleTips()} key="BB0">{strings.close + " " + strings.tips}</button>
            :
            <div className="bRow">
              <button className={"bButton"} onClick={() => this.props.toggleTips()} key="BB1">{strings.tips}</button>
              <button className={"bButton"} onClick={this.unSelect} key="BB2">{strings.unhighlight}</button>
              <button className={"bButton"} onClick={this.printRequested} key="BB3">{strings.printOrSave}</button>
              <button className={"bButton primary"} onClick={this.submitRequested} key="BB4">{strings.submit}</button>
            </div>
          }

        </div>
      </div>
    )
  }
}

export default BottomBar