import * as React from 'react'
import "../../styles/ToothEditor.css"
import Tooth from '../Tooth'
import TEQuestion from './TEQuestion'
import strings from "../../res/strings"
import incompatabilities from "../../res/incompatibleStates"

type MyProps = {
    selectedTeeth: Array<Tooth> | null
    toothComponentRefs: any | null
    submitStatus: number
    show: boolean
    isCurrentlySelecting: boolean | null
    keydown: Function
    recover: Function
    dataChanged: Function
}

type MyState = {
    selectedTeeth: null | Array<Tooth>
    showHotkeys: boolean | null
}

class ToothEditor extends React.Component<MyProps, MyState> {
    constructor(props: any) {
        super(props)
        this.state = {
            selectedTeeth: null,
            showHotkeys: !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
        }
        this.tryAgain = this.tryAgain.bind(this)
        this.handleKey = this.handleKey.bind(this)
        this.changeStateXforSelectedTeeth = this.changeStateXforSelectedTeeth.bind(this)
        this.makeQComponents = this.makeQComponents.bind(this)
        this.disableIncompatibleStates = this.disableIncompatibleStates.bind(this)
        this.toggleWholeProsthesis = this.toggleWholeProsthesis.bind(this)
        this.isToothSelected = this.isToothSelected.bind(this)
    }

    handleKey(keyEvent: KeyboardEvent) {
        if (!(keyEvent.ctrlKey || keyEvent.metaKey) && (keyEvent.keyCode >= 49 && keyEvent.keyCode <= 57 && this.state.selectedTeeth && this.state.selectedTeeth.length > 0)) {
            this.changeStateXforSelectedTeeth(keyEvent.keyCode - 49)
        } else {
            this.props.keydown(keyEvent)
        }
    }

    changeStateXforSelectedTeeth(stateId: number): void {
        let tempSelectedTeeth = this.state.selectedTeeth
        for (var tooth in tempSelectedTeeth) {
            if (tempSelectedTeeth.hasOwnProperty(tooth)) {
                let stateQuestionTemp = tempSelectedTeeth[tooth].state.toothStateQuestions
                for (var state in stateQuestionTemp) {
                    if (stateQuestionTemp.hasOwnProperty(state)) {
                        if (stateQuestionTemp[state].id === stateId) {
                            if (stateQuestionTemp[state].codebookName === 4240932) {
                                this.toggleWholeProsthesis()
                                this.disableIncompatibleStates(stateId)
                                return
                            }
                            stateQuestionTemp[state].answer = !stateQuestionTemp[state].answer
                        }
                    }
                }
                tempSelectedTeeth[tooth].setState({
                    toothStateQuestions: stateQuestionTemp
                })
            }
        }
        this.setState({
            selectedTeeth: tempSelectedTeeth
        })
        this.disableIncompatibleStates(stateId)
    }

    toggleWholeProsthesis(): void {//TODO hvis selected teeth på, skru alt av
        let toggleUpper = false
        let toggleLower = false
        let wholeProsthesisOnOff = false
        for (var tooth1 in this.state.selectedTeeth) {
            if (this.state.selectedTeeth.hasOwnProperty(tooth1)) {
                if (this.state.selectedTeeth[tooth1]) {
                    if (this.state.selectedTeeth[tooth1].props.id < 30) {
                        toggleUpper = true
                        if (toggleLower) {
                            break
                        }
                    } else if (this.state.selectedTeeth[tooth1].props.id > 30) {
                        toggleLower = true
                        if (toggleUpper) {
                            break
                        }
                    }
                }
                let selectedStates = this.state.selectedTeeth[tooth1].state.toothStateQuestions
                for (var state in selectedStates) {
                    if (selectedStates.hasOwnProperty(state) && selectedStates[state].codebookName === 4240932) {
                        wholeProsthesisOnOff = !selectedStates[state].answer
                    }
                }
            }
        }

        for (var toothRef in this.props.toothComponentRefs) {
            if (this.props.toothComponentRefs.hasOwnProperty(toothRef)) {
                if (this.props.toothComponentRefs[toothRef] && this.props.toothComponentRefs[toothRef].current) {
                    if ((this.props.toothComponentRefs[toothRef].current.props.id < 30 && toggleUpper) || (this.props.toothComponentRefs[toothRef].current.props.id > 30 && toggleLower)) {
                        let stateQuestionTemp = this.props.toothComponentRefs[toothRef].current.state.toothStateQuestions
                        let stateNamesToDisable = incompatabilities[4240932]
                        let skipDueToIncompatibility = false
                        for (var state2 in stateQuestionTemp) {
                            if (stateQuestionTemp.hasOwnProperty(state2)) {
                                if (stateQuestionTemp[state2].answer && stateNamesToDisable.includes(stateQuestionTemp[state2].codebookName)) {
                                    if (this.isToothSelected(this.props.toothComponentRefs[toothRef].current.props.id)) {//If a selected tooth is set to whole pro it should override
                                        stateQuestionTemp[state2].answer = wholeProsthesisOnOff
                                    } else {
                                        skipDueToIncompatibility = true
                                    }
                                }
                            }
                        }
                        if (!skipDueToIncompatibility) {
                            for (var state3 in stateQuestionTemp) {
                                if (stateQuestionTemp.hasOwnProperty(state3)) {
                                    if (stateQuestionTemp[state3].codebookName === 4240932) {
                                        stateQuestionTemp[state3].answer = wholeProsthesisOnOff
                                    }
                                }
                            }
                            this.props.toothComponentRefs[toothRef].current.setState({
                                toothStateQuestions: stateQuestionTemp
                            })
                        }
                    }
                }
            }
        }
    }

    isToothSelected(id: number): boolean {
        for (var tooth in this.state.selectedTeeth) {
            if (this.state.selectedTeeth.hasOwnProperty(tooth)) {
                if (this.state.selectedTeeth[tooth] && this.state.selectedTeeth[tooth].props.id === id) {
                    return true
                }
            }
        }
        return false
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKey, false)
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKey, false)
    }

    disableIncompatibleStates(prioritizedSateId: number): void {
        let stateIdentification: string | number | undefined
        if (this.state.selectedTeeth && this.state.selectedTeeth[0] && this.state.selectedTeeth[0].state.toothStateQuestions) {
            for (var stateQ in this.state.selectedTeeth[0].state.toothStateQuestions) {
                if (this.state.selectedTeeth[0].state.toothStateQuestions.hasOwnProperty(stateQ)
                    && this.state.selectedTeeth[0].state.toothStateQuestions[stateQ]
                    && (this.state.selectedTeeth[0].state.toothStateQuestions[stateQ].id === prioritizedSateId)) {
                    stateIdentification = this.state.selectedTeeth[0].state.toothStateQuestions[stateQ].codebookName
                }
            }
        }
        if (stateIdentification) {
            let stateNamesToDisable = incompatabilities[stateIdentification]
            if (stateNamesToDisable) {
                for (var tooth in this.state.selectedTeeth) {
                    if (this.state.selectedTeeth.hasOwnProperty(tooth)) {
                        if (this.state.selectedTeeth[tooth] && this.state.selectedTeeth[tooth].state.toothStateQuestions) {
                            for (var state in this.state.selectedTeeth[tooth].state.toothStateQuestions) {
                                if (this.state.selectedTeeth[tooth].state.toothStateQuestions.hasOwnProperty(state)
                                    && this.state.selectedTeeth[tooth].state.toothStateQuestions[state]
                                    && this.state.selectedTeeth[tooth].state.toothStateQuestions[state].answer
                                    && stateNamesToDisable.includes(this.state.selectedTeeth[tooth].state.toothStateQuestions[state].codebookName)) {
                                    let sQTemp = this.state.selectedTeeth[tooth].state.toothStateQuestions
                                    sQTemp[state].answer = false
                                    this.state.selectedTeeth[tooth].setState({ toothStateQuestions: sQTemp })
                                }
                            }
                        }
                    }
                }
            }
        }
        this.props.dataChanged()
        this.forceUpdate()//TODO avoid force
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selectedTeeth !== state.selectedTeeth) {
            return { selectedTeeth: props.selectedTeeth }
        }
        return null
    }

    getIdsString(): string {
        let tempIdsS = ""
        if (this.state.selectedTeeth && this.state.selectedTeeth[0]) {
            tempIdsS += this.state.selectedTeeth[0].props.id
        }
        if (this.state.selectedTeeth) {
            for (let i = 1; i < this.state.selectedTeeth.length; i++) {
                tempIdsS += ", " + this.state.selectedTeeth[i].props.id
            }
        }
        return tempIdsS
    }

    tryAgain(): void {
        this.props.recover()
    }

    makeQComponents(): Array<any> {
        let questionComponents: Array<any> = []
        if (this.state.selectedTeeth && this.state.selectedTeeth.length > 0) {
            for (var stateQuestion in this.state.selectedTeeth[0].state.toothStateQuestions) {
                if (this.state.selectedTeeth[0].state.toothStateQuestions.hasOwnProperty(stateQuestion)) {
                    let tempVal = this.state.selectedTeeth[0].state.toothStateQuestions[stateQuestion].answer
                    for (var tooth in this.state.selectedTeeth) {
                        if (this.state.selectedTeeth.hasOwnProperty(tooth)) {
                            if (this.state.selectedTeeth[tooth] && this.state.selectedTeeth[tooth].state.toothStateQuestions[stateQuestion])
                                if (this.state.selectedTeeth[tooth].state.toothStateQuestions[stateQuestion].answer !== tempVal) {
                                    tempVal = null
                                    break
                                }
                        }
                    }
                    questionComponents.push(
                        <TEQuestion
                            question={this.state.selectedTeeth[0].state.toothStateQuestions[stateQuestion].question}
                            placeholder={tempVal}
                            hasMixedAnswers={tempVal === null}
                            passOnUserInput={this.changeStateXforSelectedTeeth}
                            key={"TEQ m" + this.state.selectedTeeth[0].props.id + stateQuestion}
                            id={this.state.selectedTeeth[0].state.toothStateQuestions[stateQuestion].id}
                            showHotKeyHints={this.state.showHotkeys}
                        />
                    )
                }
            }
        }
        return questionComponents
    }

    render() {
        let questionComponents = this.makeQComponents()
        return (
            <div id="ToothEditorMenu" role="menu">
                <h1
                    className={"centerInstructions"
                        + (this.props.submitStatus === 0 && this.props.show
                            ? " invisible" :
                            this.props.isCurrentlySelecting && this.props.submitStatus === 0
                                ? " invisibleFade"
                                : " visibleFade")}
                >
                    {this.props.submitStatus === 0 ? strings.centerInstructions : (this.props.submitStatus <= 1 && this.props.submitStatus > 0) ? strings.preparingSubmit : (this.props.submitStatus > 1 ? strings.submitSuccess : strings.submitFail)}
                </h1>
                {this.props.submitStatus === 0 && this.props.show ?
                    <div className={"ToothEditor" + (this.state.selectedTeeth && this.state.selectedTeeth.length > 0 ? " visibleFade" : " invisible")}>
                        <h2 className="toothStatus">
                            {(this.state.selectedTeeth && this.state.selectedTeeth.length > 0) ?
                                (strings.toothEditStatus + " " + ((this.state.selectedTeeth && this.state.selectedTeeth.length > 1) ? strings.theTeeth : strings.tooth) + " " + this.getIdsString() + ":")
                                :
                                "Ingen tenner valgt"
                            }
                        </h2>
                        <form className="ToothEditorForm">
                            {questionComponents ?
                                questionComponents
                                :
                                <h2>{strings.loading}</h2>
                            }
                        </form>

                    </div>
                    :
                    this.props.submitStatus === 2 ?
                        <a className="center" style={{ animation: `fadeIn 1.5s` }} href="https://nettskjema.no/a/129879 ">{strings.sendAgain}</a>
                        :
                        this.props.submitStatus === -1 &&
                        <button
                            className="bButton"
                            onClick={this.tryAgain}
                            style={{ animation: `fadeIn 1.5s`, marginTop: ".5em" }}>
                            {strings.ok}
                        </button>
                }
            </div>
        )
    }
}

export default ToothEditor