import * as React from "react";

type MyProps = {
  textContent: any;
  components: any;
};

type MyState = {};

class Equipment extends React.Component<MyProps, MyState> {
  componentDidMount() {
    //Hent backup og bruk den om textcontent mangler noe
  }

  render() {
    return (
      <div className="Equipment roomBox scrollable">
        <div className="roomBoxScrollableContent">
          {this.props.components ? (
            this.props.components.length > 0 ? (
              this.props.textContent && this.props.textContent["3"] ? (
                <div>
                  <h2 className="subtitle">{this.props.textContent["3"]}</h2>
                  <ul className="equipmentList">{this.props.components}</ul>
                </div>
              ) : (
                <h3>Laster inn...</h3>
              )
            ) : (
              <h2 className="subtitle">Null utstyr</h2>
            )
          ) : (
            <h2 className="subtitle">Fant ikke utstyr</h2>
          )}
        </div>
      </div>
    );
  }
}

export default Equipment;
