import * as React from "react";

type MyProps = {
  textContent: any;
  components: any;
};

type MyState = {
  textContent: any;
};

class Procedure extends React.Component<MyProps, MyState> {
  componentDidMount() {
    //Hent backup og bruk den om textcontent mangler noe
  }

  render() {
    return (
      <div className="Procedure roomBox scrollable">
        <div className="roomBoxScrollableContent">
          {this.props.components ? (
            this.props.components.length > 0 ? (
              this.props.textContent && this.props.textContent["5"] ? (
                <div>
                  <h2 className="subtitle">{this.props.textContent["5"]}</h2>
                  <ul className="procedureList">{this.props.components}</ul>
                </div>
              ) : (
                <h3>Laster inn...</h3>
              )
            ) : (
              <h2 className="subtitle">Ingen ekstra prosedyre</h2>
            )
          ) : (
            <h2 className="subtitle">Fant ingen ekstra prosedyre</h2>
          )}
        </div>
      </div>
    );
  }
}

export default Procedure;
