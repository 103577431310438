import * as React from 'react'
import '../../styles/Legend.css'
type MyProps = {
  toothData: any
}

type MyState = {
  presentStateComponents: Array<any> | null
}

class Legend extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props)
    this.state = {
      presentStateComponents: null
    }
  }

  componentDidMount() {
    let presentStates: Set<String> = new Set()
    for (var tooth in this.props.toothData) {
      if (this.props.toothData.hasOwnProperty(tooth) && this.props.toothData[tooth].states) {
        for (var toothState in this.props.toothData[tooth].states) {
          if (this.props.toothData[tooth].states.hasOwnProperty(toothState) && this.props.toothData[tooth].states[toothState].answer) {
            presentStates.add(this.props.toothData[tooth].states[toothState].question)
          }
        }
      }
    }
    let presentStateComponents: Array<any> = []
    presentStates.forEach(s => {
      presentStateComponents.push(
        <a
          href={"/s/info/" + s}
          className="legendItem"
          key={"legendH" + s}
        >
          {s}
        </a>
      )
    }
    )
    this.setState({
      presentStateComponents: presentStateComponents
    })
  }

  render() {
    return (
      <div className="Legend">
        {this.props.toothData && this.state.presentStateComponents &&
          this.state.presentStateComponents
        }
      </div>
    )
  }
}

export default Legend