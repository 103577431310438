import * as React from "react";
import "../../styles/Room.css";
import history from "./history";

type MyProps = {};

type MyState = {};

class NotFound extends React.Component<MyProps, MyState> {
  render() {
    return (
      <div>
        <div className="centerChildrenHorizontaly">
          <h1 className="pageTitle">Siden finnes ikke</h1>
        </div>
        <h2 className="subtitle">Det kan hjelp å hente ny data til systemet</h2>
        <button className="squareButton" onClick={() => history.go(-1)}>
          Tilbake til Tannkart
        </button>
        <button
          className="squareButton"
          onClick={() => history.push("/s/data")}
        >
          Hent data
        </button>
      </div>
    );
  }
}

export default NotFound;
