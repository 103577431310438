const strings = {
  centerInstructions: "Velg tenner for å oppdatere og se informasjon",
  unknownErrorMessage: "Noe gikk galt. Prøv gjerne å velge tann på nytt.", //Denne feilen har aldri inntruffet, men det ligger generisk feilmelding klar i tilfelle
  toothEditStatus: "Tilstandene til",
  theTeeth: "tennene",
  tooth: "tann",
  missing: "Mangler",
  partProthesis: "Delprotese",
  wholeProsthesis: "Helprotese",
  bridge: "Bro",
  bridgeConnection: "BroKobling",
  crown: "Krone",
  rest: "Rotrest",
  implant: "Implantat",
  coating: "Belegg",
  toothAltTitle: "Tann",
  toothAltDesc: "Med id",
  toothAltDescStates: "Med tilstander:",
  affermative: "ja",
  ok: "OK",
  negative: "nei",
  close: "Lukk",
  submit: "Send skjema",
  printOrSave: "Lagre som pdf / Skriv ut",
  currentlyFetchingData: "Henter data...",
  loading: "laster inn....",
  building: "bygger nettside...",
  preparingSubmit: "forbereder innsending...",
  submitSuccess: "Skjemaet ble sendt",
  submitFail:
    "Skjemaet ble ikke sendt. Sjekk nettverkstilkobling og prøv igjen.",
  sendAgain: "Send et nytt skjema",
  wannaPrint:
    "Skjemaet kan ikke skrives ut etter det har blitt sendt. \nTrykk [Avbryt] for å gå tilbake og skrive ut først. \nTrykk [OK] om du vil sende skjemaet uten å skrive det ut.",
  dataFetchError: "Kunne ikke hente skjema. Prøv gjerne på nytt igjen om litt.",
  noTemplate:
    "Kunne ikke hente mal for tannkart. Vil du sende et svar basert på gammel mal? (dette må senere rettes manuelt i datasettet)",
  aborted: "Prøv gjerne igjen om litt",
  doPart1First:
    "Vi fant ingen koblingsnøkkel til Nettskjema for del 1. Ønsker du å fylle ut Nettskjema for del 1 først, slik at informasjonen samles og kan knyttes til en pasient?",
  fetchErrorContinue:
    "Systemet klarte ikke hente oppdatert liste av spørsmål og mulige tilstander. Vil du fortsette uten forsikkring at du har de nyeste spørsmålene og tilstandene til tennnene?",
  usingBackup:
    "Systemet kunne ikke hente oppdatert liste av spørsmål og mulige tilstander. Det kan derfor mangle informasjon eller spørsmål. Se gjerne om feilen vedvarer etter å ha ventet litt og lastet inn siden på nytt, før du fortsetter å fylle ut denne.",
  noKey: "Ingen koblingsnøkkel funnet for Nettskjema del 1.",
  noKeyConsequences:
    "Svar må manuelt kobles sammen senere i databasen om du fortsetter.",
  confirmContinue: "Ønsker du å fortsette?",
  nettSkjemaKeyVarName: "k",
  patientIdVarName: "p",
  tipsText:
    "Bruk Piltastene og (Tab) knappen for å navigere blant tennnene og knappene.\n\nVelg tenner med (Mellomrom) eller (Enter).\n\nVelg flere tenner samtidig ved å holde inne (Shift) mens du trykker, eller ved å holde inne venstretrykk på musen og dra den over tenner.\n\nTrykk på Escape knappen (Esc) eller (A) for å avmarkere alle valgte tenner.\n\nHver tilstand kan skrus på/av ved å trykke på tilsvarende nummer på tastaturet (1-9).\n\nTrykk på (i) for å vise eller skjule tenner sin id.",
  pageTitle: "Tannstatus",
  sendShortCutInfo:
    "Du trykket på (S) som er hurtigtasten for å sende skjemaet. Du kan ikke skrive ut eller lagre skjemaet som pdf etter å ha sendt skjemaet.\nØnsker du å sende skjemaet nå?\n(Trykk på (Enter) for å bekrefte)",
  unhighlight: "Avmarker alle tenner",
  tips: "Tips",

  //Tekst og nøkkler til localstorage delen av systemet for sykehjemsansatte
  nullStringKey: "*",
  selectedFloorKey: "selectedFloor",
  selectedRoomKey: "selectedRoom",
  dataKey: "data",
  contentFromNettskjemaKey: "contentFromNettskjema",
  externalTopicsToLinkKey: "externalTopicsToLink",
  missingData: "data mangler",
  resourcesKey: "generellinfohtml",
  stateLogicKey: "stateprocedureequipmentlogic",
};

export default strings;
