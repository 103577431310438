import * as React from "react";

type MyProps = {
  title: string | undefined;
  components: Array<any> | null;
};

type MyState = {};

class States extends React.Component<MyProps, MyState> {
  render() {
    console.log(" COMPONENTS ", this.props.components);
    return (
      <div className="States roomBox scrollable">
        <div className="roomBoxScrollableContent">
          {this.props.components ? (
            this.props.components.length > 0 ? (
              this.props.title ? (
                <div>
                  <h2 className="subtitle">{this.props.title}</h2>
                  <ul className="stateList">{this.props.components}</ul>
                </div>
              ) : (
                <h3>...</h3>
              )
            ) : (
              <h2 className="subtitle">Ingen tilstander</h2>
            )
          ) : (
            <h2 className="subtitle">Ingen tilstander funnet</h2>
          )}
        </div>
      </div>
    );
  }
}

export default States;
