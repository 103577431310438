import * as React from 'react'
import "../../styles/TEQuestion.css"

type MyProps = {
    id: number
    question: string,
    placeholder: boolean | number | string,
    passOnUserInput: Function,
    hasMixedAnswers: boolean
    showHotKeyHints: boolean | null
}

type MyState = {
    answer: any
}

class TEQuestion extends React.Component<MyProps, MyState> {
    constructor(props: any) {
        super(props)
        this.state = {
            answer: props.placeholder
        }
        this.handleCheck = this.handleCheck.bind(this)
    }

    handleCheck(event: any): void {
        if (event && event.target) {
            this.props.passOnUserInput(this.props.id, event.target.checked)
            this.setState({ answer: event.target.checked })
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.placeholder !== state.answer) {
            return { answer: props.placeholder }
        }
        return null
    }

    render() {
        let classNames = "TEQ TEQCheckbox"
        if (this.props.hasMixedAnswers) {
            classNames += " mixed"
        } else if (this.state.answer) {
            classNames += " checked"
        }
        let hotkeyText = ""
        if (this.props.showHotKeyHints) {
            if (this.props.id < 9) {
                hotkeyText = " (" + (this.props.id + 1) + ")"
            }
        }
        return (
            <label className={classNames}
                key={this.props.id + "TEQ1"}     >
                <input
                    type="checkbox"
                    onChange={this.handleCheck}
                    key={this.props.id + "TEQ3"}
                    checked={this.state.answer || false}//-> placeholder
                />
                <h3>{this.props.question + hotkeyText}</h3>
            </label>
        )
    }
}

export default TEQuestion