import * as React from "react";
import { RouteProps } from "react-router";
import "../../styles/Room.css";
import TeethRow from "../TeethRow";
import Tooth from "../Tooth";
import Procedure from "./Procedure";
import States from "./States";
import Equipment from "./Equipment";
import Comments from "./Comments";
import Legend from "./Legend";
import history from "./history";
import strings from "../../res/strings";
import ColorChart from "./ColorChart";
import {
  teethHaveState,
  countWhiteTeeth,
  numPartProstheses,
  numWholeProstheses,
  formatDate,
} from "../../utils";

type MyProps = {};

type MyState = {
  lData: any | null;
  toothData: any | null;
  roomNum: string | null;
  floorNum: string | null;
  toothDataWriteDate: string | null;
  nettskjemaContent: any | null;
  pStates: any | null;
  assistance: "1" | "2" | "3" | "0" | null;
  comments: any | null;
  stateComponents: any;
  procedureComponents: any;
  equipmentComponents: any;
  bsi: number | null;
};

class Room extends React.Component<MyProps & RouteProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      toothData: null,
      lData: null,
      roomNum: null,
      floorNum: null,
      toothDataWriteDate: null,
      nettskjemaContent: null,
      assistance: null,
      pStates: null,
      comments: null,
      stateComponents: null,
      procedureComponents: null,
      equipmentComponents: null,
      bsi: null,
    };
    this.toothClickHandler = this.toothClickHandler.bind(this);
    this.getFloor = this.getFloor.bind(this);
    this.getRoom = this.getRoom.bind(this);
    this.createPatientComponents = this.createPatientComponents.bind(this);
  }

  componentDidMount() {
    let floor = this.getFloor();
    if (floor) {
      localStorage.setItem(strings.selectedFloorKey, floor);
    } else {
      floor = localStorage.getItem(strings.selectedFloorKey);
    }
    let room = this.getRoom();
    if (room) {
      localStorage.setItem(strings.selectedRoomKey, room);
    } else {
      room = localStorage.getItem(strings.selectedRoomKey);
    }
    let data: any = localStorage.getItem(strings.dataKey);
    let toothData: Object | null = null;
    let pStates: any;
    let comments: any = [];
    let toothDataWriteDate: any;
    let bsi: any;
    let assistance: "1" | "2" | "3" | "0" = "0";

    if (data && room) {
      let dataAsObject = JSON.parse(data);
      if (dataAsObject && dataAsObject.constructor === Object) {
        for (var patient in dataAsObject) {
          if (
            dataAsObject.hasOwnProperty(patient) &&
            dataAsObject[patient]["room"] &&
            dataAsObject[patient]["room"] === room
          ) {
            const data = dataAsObject[patient];
            toothData = data.toothData;
            pStates = data.states;
            const comment = data.comment;

            if (comment) {
              comments.push({
                by: data.author || "Ukjent dato",
                date: data.toothDataWriteDate || "ukjent forfatter",
                content: comment,
              });
            }

            toothDataWriteDate = data.toothDataWriteDate;
            bsi = data.bsi;
            assistance = data.assistance;
          }
        }
      }
    }
    let nettskjemaContent = localStorage.getItem(
      strings.contentFromNettskjemaKey,
    );
    if (nettskjemaContent) {
      nettskjemaContent = JSON.parse(nettskjemaContent);
    }
    this.setState({
      floorNum: floor,
      roomNum: room,
      lData: data,
      assistance: assistance,
      toothDataWriteDate: toothDataWriteDate,
      toothData: toothData,
      nettskjemaContent: nettskjemaContent,
      pStates: pStates,
      comments: comments,
      bsi: bsi,
    });

    this.createPatientComponents(
      pStates,
      nettskjemaContent,
      comments,
      toothData,
      assistance,
    );
  }

  createPatientComponents(
    pStates,
    nettskjemaContent,
    comments,
    toothData,
    assistance,
  ) {
    let stateConnections; //TODO flytt til data management
    if (nettskjemaContent && nettskjemaContent[strings.stateLogicKey]) {
      stateConnections = nettskjemaContent[strings.stateLogicKey];
      console.log(stateConnections);
    } else {
      stateConnections = require("./stateConnectionsBackup.json");
      console.log("got backup");
      if (stateConnections["backup1"]) {
        stateConnections = stateConnections["backup1"];
      } else {
        stateConnections = null;
      }
    }
    stateConnections = stateConnections
      .replace(/<[^>]+>/g, "")
      .replace(/\n/gi, "");
    console.log(stateConnections);
    stateConnections = stateConnections.split("{");
    console.log(stateConnections);

    let stateProcedureEquipmentConnections;
    stateProcedureEquipmentConnections = {};
    stateProcedureEquipmentConnections.states = [];
    stateProcedureEquipmentConnections.procedures = [];
    stateProcedureEquipmentConnections.equipment = [];
    //TODO denne logikken ble jeg ikke ferdig med. Se redeklarasjonen nedenfor
    // for et eksempel på hva jeg ønsket å hente ut. Om dette blir tungvindt kan
    // vi bare legge inn logikken her. Men omOdontologi-folka ønsker å justere
    // på denne logikken hadde det vært fint om det ble hentet fra tannkart
    // systeminnhold nettskjemaet.
    stateConnections.forEach((t) => {
      console.log(t);
      t = t.split("}");
      if (t && t[0] && t.length === 2) {
        let connection = t[0].split(":");
        if (
          connection &&
          connection[0] &&
          (connection.length === 3 || connection.length === 4)
        ) {
          switch (connection[0].toLowerCase()) {
            case "tilstand":
              stateProcedureEquipmentConnections.states.push({
                id: connection[1],
                name: connection[2],
              });
              break;
            case "prosedyre":
              stateProcedureEquipmentConnections.procedures.push({
                id: connection[1],
                name: connection[2],
                appliesTo: connection[3],
              });
              break;
            case "utstyr":
              stateProcedureEquipmentConnections.equipment.push({
                id: connection[1],
                name: connection[2],
                appliesTo: connection[3],
              });
              break;
          }
        }
      }
    });
    console.log({ stateConnections });
    console.log({ stateProcedureEquipmentConnections });
    stateProcedureEquipmentConnections = {
      states: [
        //Hvilke tilstander finnes
        {
          id: "info10",
          name: "Munntørrhet",
        },
        {
          id: "info9",
          name: "Tannkjøttbetennelse",
        },
      ],
      procedures: [
        //Hvilke prosedyrer finnes, og hvilke tilstander skal de vises for?
        {
          id: "info5",
          name: "Soppinfeksjon",
          appliesTo: "info5",
        },
        {
          id: "info10",
          name: "Munntørrhet",
          appliesTo: "info10",
        },
        {
          id: "info40",
          name: "Helprotese",
          appliesTo: "",
        },
        {
          id: "info40",
          name: "Delprotese",
          appliesTo: "",
        },
        {
          id: "info41",
          name: "Implantat + bro",
          appliesTo: "",
        },
        {
          id: "info22",
          name: "Tannpuss (Klarer selv)",
          appliesTo: "",
        },
        // Tannpuss delvis hjelp
        /*{
          id: ["info43", "info23"],
          name: "Bruke mellomrombørste",
          appliesTo: "",
        },
        {
          id: ["info44", "info23"],
          name: "Pusse tenner",
          appliesTo: "",
        },
        {
          id: ["info42", "info23"],
          name: "Ta ut og rengjøre protese",
          appliesTo: "",
        },
        {
          id: ["info45", "info23"],
          name: "Bruke mellomromsbørste + Pusse tenner",
          appliesTo: "",
        },
        {
          id: ["info46", "info23"],
          name: "Bruke mellomromsbørste + Ta ut og rengjøre protese",
          appliesTo: "",
        },
        {
          id: ["info47", "info23"],
          name: "Pusse tenner + Ta ut og rengjøre protese",
          appliesTo: "",
        },
        {
          id: ["info48", "info23"],
          name:
          "Bruke mellomromsbørste + Pusse tenner + Ta ut og rengjøre protese",
          appliesTo: "",
        },
        */
        // end delvis hjelp
        /*{
          id: "info111",
          name: "Tannpuss",
          appliesTo: "info10",
        },*/
      ],
      equipment: [
        //Hvilket utstyr finnes, og hvilke prosedyrer skal de vises for?
        {
          id: "info122",
          name: "Protesebørste",
          appliesTo: "info111",
        },
        {
          id: "info28",
          name: "Tannpasta med 1450 pp, fluorid...",
          appliesTo: "",
        },
        {
          id: "info29",
          name: "Duraphattannpasta...",
          appliesTo: "",
        },
        {
          id: "info30",
          name: "Fluoridskyll...",
          appliesTo: "",
        },
        {
          id: "info31",
          name: "Fluoridtabletter",
          appliesTo: "",
        },
        {
          id: "info37",
          name: "Salivasubstituter",
          appliesTo: "info10",
        },
      ],
    };
    //Ta med id også, legg til i komponenter. bruk id i states også
    let stateComponents: Array<any> = [];
    let equipmentComponents: Array<any> = [];
    let procedureComponents: Array<any> = [];

    // stateComponents.push("Munntørrhet");

    const topicData: any = localStorage.getItem(
      strings.externalTopicsToLinkKey,
    );
    const topics: any = JSON.parse(topicData);

    for (let patientState in pStates) {
      if (pStates.hasOwnProperty(patientState)) {
        console.log({ patientState });
        //TODO trenger id også
        let stateId: string = stripId(pStates[patientState]);

        console.log({ topics, stateId });

        const stateName = topics[stateId] ? stateId : `ukjent: ${stateId}`;

        for (let aState of stateProcedureEquipmentConnections.states) {
          console.log("aState: ", aState);
        }

        stateComponents.push(stateName);

        // Procedures
        for (
          let i = 0;
          i < stateProcedureEquipmentConnections.procedures.length;
          i++
        ) {
          const procedure = stateProcedureEquipmentConnections.procedures[i];

          if (procedure && procedure.appliesTo) {
            // let procedureAppliesTo: string = procedure.appliesTo;
            const procedureAppliesToArray = Array.isArray(procedure.appliesTo)
              ? procedure.appliesTo
              : [procedure.appliesTo];

            for (let procedureAppliesTo of procedureAppliesToArray) {
              if (procedureAppliesTo.localeCompare(stateId) === 0) {
                console.log(
                  "Found procedure: " + procedure.name + " for " + stateId,
                );

                //Add procedure component
                procedureComponents.push(procedure.id);

                // Equipment
                for (
                  let j = 0;
                  j < stateProcedureEquipmentConnections.equipment.length;
                  j++
                ) {
                  const equipped =
                    stateProcedureEquipmentConnections.equipment[j];

                  if (equipped) {
                    let equipmentAppliesTo: string = equipped.appliesTo;
                    let procedureId = equipped.id;
                    if (equipmentAppliesTo.localeCompare(procedureId) === 0) {
                      console.log(
                        "Found equipment: " +
                          equipped.name +
                          " for " +
                          equipped.appliesTo,
                      );
                      //Add equipment component
                      equipmentComponents.push(equipped.id);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    /*
        if (pStates && nettskjemaContent && stateConnections && stateConnections.default) {
          stateConnections = stateConnections.default
          for (var s in pStates) {
            if (pStates[s] && nettskjemaContent[pStates[s]]) {
              if (stateConnections[pStates[s]]) {
                stateComponents.push(nettskjemaContent[pStates[s]])
                if (stateConnections[pStates[s]].equipment) {
                  for (var equipmentId in stateConnections[pStates[s]].equipment) {
                    if (stateConnections[pStates[s]].equipment[equipmentId] && nettskjemaContent[stateConnections[pStates[s]].equipment[equipmentId]]) {
                      equipmentComponents.push(
                        nettskjemaContent[stateConnections[pStates[s]].equipment[equipmentId]]
                      )
                    }
                  }
                }
                if (stateConnections[pStates[s]].procedure) {
                  for (var procedureId in stateConnections[pStates[s]].procedure) {
                    if (stateConnections[pStates[s]].procedure[procedureId] && nettskjemaContent[stateConnections[pStates[s]].procedure[procedureId]]) {
                      procedureComponents.push(
                        nettskjemaContent[stateConnections[pStates[s]].procedure[procedureId]]
                      )
                    }
                  }
                }
              }
            }
          }
        }*/

    //
    // Diverse annet utstyr
    //

    // Vises på alle
    equipmentComponents.push("info26");

    // Hvis mer enn to egne tenner (hvite tenner) og/eller "implantat + bropilar"
    const egneTenner = countWhiteTeeth(toothData) > 2;
    const implantatOgBropilar =
      teethHaveState(toothData, "implant") &&
      teethHaveState(toothData, "bridge");
    if (egneTenner || implantatOgBropilar) {
      topics["info33"] && equipmentComponents.push("info33");
    }

    // Dersom det er hel- eller delprotese (dersom det både er hel- OG
    // del-protese i en og samme munn, trennger det bare koppe opp en gang)
    if (numPartProstheses(toothData) > 0 || numWholeProstheses(toothData) > 0) {
      topics["info27"] && equipmentComponents.push("info27");
      topics["info35"] && equipmentComponents.push("info35");
      topics["info38"] && equipmentComponents.push("info38");
    }

    //
    // Andre prosedyrer
    //

    // Tannpuss
    // 0: Ikke besvart
    // 1: Klarer selv
    // 2: Delvis hjelp
    // 3: Full hjelp
    if (assistance === "1") {
      procedureComponents.push("info22");
    } else if (assistance === "2") {
      procedureComponents.push("info23");
      // topics["info43"] && procedureComponents.push(topics["info43"].path);
      // topics["info44"] && procedureComponents.push(topics["info44"].path);
      // topics["info42"] && procedureComponents.push(topics["info42"].path);
      // topics["info45"] && procedureComponents.push(topics["info45"].path);
      // topics["info46"] && procedureComponents.push(topics["info46"].path);
      // topics["info47"] && procedureComponents.push(topics["info47"].path);
      // topics["info48"] && procedureComponents.push(topics["info48"].path);
    } else if (assistance === "3") {
      procedureComponents.push("info24");
    }

    // Delprotese eller helprotese
    if (numPartProstheses(toothData) > 0 || numWholeProstheses(toothData) > 0) {
      topics["info40"] && procedureComponents.push("info40");
    }

    // Implantat + bro
    if (
      teethHaveState(toothData, "implant") &&
      teethHaveState(toothData, "bridge")
    ) {
      topics["info41"] && procedureComponents.push("info41");
    }

    stateComponents = Array.from(new Set(stateComponents));
    equipmentComponents = Array.from(new Set(equipmentComponents));
    procedureComponents = Array.from(new Set(procedureComponents));

    stateComponents = stateComponents.map((s) => {
      const topic = topics[s];
      let title = s;
      let id = s;
      let key = s;

      if (topic) {
        title = topic.path;
      }

      return (
        <li className="state" key={key}>
          <a href={"/s/info/" + id}>{title}</a>
        </li>
      );
    });

    equipmentComponents = equipmentComponents.map((e) => {
      const topic = topics[e];
      let title = e;
      let id = e;
      let key = e;

      if (topic) {
        title = topic.path;
      }

      return (
        <li className="equipment" key={key}>
          <a href={"/s/info/" + id}>{title}</a>
        </li>
      );
    });

    procedureComponents = procedureComponents.map((p) => {
      const topic = topics[p];
      let title = p;
      let id = p;
      let key = p;

      if (topic) {
        title = topic.path;
      }

      return (
        <li className="procedure" key={key}>
          <a href={"/s/info/" + id}>{title}</a>
        </li>
      );
    });

    this.setState({
      stateComponents: stateComponents,
      equipmentComponents: equipmentComponents,
      procedureComponents: procedureComponents,
    });
  }

  getRoom(): string | null {
    if (this.props.match)
      if (this.props.match.params.roomNumber !== strings.nullStringKey)
        return this.props.match.params.roomNumber;
    return null;
  }

  getFloor(): string | null {
    if (this.props.match)
      if (this.props.match.params.floorNumber !== strings.nullStringKey)
        return this.props.match.params.floorNumber;
    return null;
  }

  /**
   * Handle mouse events on teeth. Hover-effects are disabled for missing or
   * normal teeth; this is done in src/components/Tooth.tsx: toothMouseEnter(event)
   */
  toothClickHandler(aTooth: Tooth, event: any | null): void {
    if (aTooth) {
      if (event) {
        if (event.type === "mousedown" || event.type === "keydown") {
          const tooth = this.state.toothData?.find(
            (t) => t.id === aTooth?.props?.id,
          );
          const hasState = (state) =>
            tooth &&
            tooth.states &&
            tooth.states[state] &&
            tooth.states[state].answer;

          if (hasState("implant") && hasState("bridge")) {
            history.push("/s/info/info13");
            return;
          }

          if (hasState("implant") && hasState("crown")) {
            history.push("/s/info/info16");
            return;
          }

          if (
            hasState("implant") &&
            (hasState("partProsthesis") || hasState("wholeProsthesis"))
          ) {
            history.push("/s/info/info40");
            return;
          }

          if (hasState("partProsthesis")) {
            history.push("/s/info/info12");
            return;
          }

          if (hasState("wholeProsthesis")) {
            history.push("/s/info/info1");
            return;
          }

          if (hasState("bridge") || hasState("bridgeConnection")) {
            history.push("/s/info/info15");
            return;
          }

          if (hasState("crown")) {
            history.push("/s/info/info16");
            return;
          }

          if (hasState("rest")) {
            history.push("/s/info/info2");
            return;
          }

          if (hasState("implant")) {
            history.push("/s/info/info8");
            return;
          }

          if (hasState("coating")) {
            // Utgår
          }
        }
      }
    }
  }

  render() {
    return (
      <div className="Room">
        {!this.state.lData ? (
          <div>
            <div className="centerChildrenHorizontaly">
              <h1 className="pageTitle">Rom</h1>
            </div>
            <h2 className="subtitle">Fant ingen data å vise frem</h2>
            <button
              className="squareButton"
              onClick={() => history.push("/s/data")}
            >
              Adminstrer data{" "}
            </button>
          </div>
        ) : !this.state.floorNum ? (
          <div>
            <div className="centerChildrenHorizontaly">
              <h1 className="pageTitle">Rom</h1>
            </div>
            <h2 className="subtitle">Etasje er ikke valgt</h2>
            <button
              className="squareButton"
              onClick={() => history.push("/s/etasje")}
            >
              Etasjeoversikt
            </button>
          </div>
        ) : !this.state.roomNum ? (
          <div>
            <div className="centerChildrenHorizontaly">
              <h1 className="pageTitle">Rom</h1>
            </div>
            <h2 className="subtitle">Rom er ikke valgt</h2>
            <button
              className="squareButton"
              onClick={() =>
                history.push("/s/etasje/" + this.state.floorNum + "/rom/")
              }
            >
              Romoversikt
            </button>
          </div>
        ) : (
          <div className="col0">
            <div className="centerChildrenHorizontaly">
              <div className="pageTitleContainer">
                <h1 className="pageTitle">{"Rom " + this.state.roomNum}</h1>
                {this.state.toothDataWriteDate && (
                  <div className="pageSubtitle">
                    {"Siste tannundersøkelse " +
                      formatDate(this.state.toothDataWriteDate)}
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col1">
                <div className="sToothChart roomBox">
                  {this.state.toothData &&
                  typeof this.state.toothData === "object" &&
                  Object.keys(this.state.toothData).length > 0 ? ( //TODO flytt til egen komponent
                    <div className="loadedToothChart">
                      {this.state.nettskjemaContent &&
                        this.state.nettskjemaContent["1"] && (
                          <h2 className="subtitle" style={{ marginTop: 0 }}>
                            {this.state.nettskjemaContent["1"]}
                          </h2>
                        )}
                      <div className="teethAndInfoContainer">
                        {/* Left column */}
                        <div
                          style={{
                            flexBasis: "25%",
                            flexShrink: 0,
                          }}
                        >
                          <ColorChart toothData={this.state.toothData} />
                          {this.state.bsi && (
                            <div className="bsiInfoBlock">
                              BSI = {this.state.bsi}
                            </div>
                          )}
                        </div>
                        {/* Right column */}
                        <div style={{ flexGrow: 1 }}>
                          <div className="teethRows">
                            <TeethRow
                              key="TC1"
                              id=" øverst"
                              toothChartAction={this.toothClickHandler}
                              shareRefs={null}
                              toothData={this.state.toothData}
                              upper={true}
                              moveForEditor={null}
                              submitStatus={0}
                              showIds={false}
                              simplified
                            />

                            <TeethRow
                              key="TC2"
                              id=" nederst"
                              toothChartAction={this.toothClickHandler}
                              shareRefs={null}
                              toothData={this.state.toothData}
                              upper={false}
                              moveForEditor={null}
                              submitStatus={0}
                              showIds={false}
                              simplified
                            />
                          </div>
                        </div>
                        <Legend toothData={this.state.toothData} />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <h3 className="h3">
                        Informasjon om tenner eksisterer ikke, eller har ikke
                        blitt hentet
                      </h3>
                      {this.state.lData && this.state.lData.updatedTime && (
                        <h4 className="h4">
                          Data ble sist hentet {this.state.lData.updatedTime}
                        </h4>
                      )}
                      <button
                        className="squareButton"
                        onClick={() => history.push("/s/data")}
                      >
                        Hent data
                      </button>
                    </div>
                  )}
                </div>
                <div className="row2">
                  <States
                    title={this.state.nettskjemaContent["2"]}
                    components={this.state.stateComponents}
                  />
                  <Equipment
                    textContent={this.state.nettskjemaContent}
                    components={this.state.equipmentComponents}
                  />
                </div>
              </div>

              <div className="col2">
                <Procedure
                  textContent={this.state.nettskjemaContent}
                  components={this.state.procedureComponents}
                />
                <Comments
                  textContent={this.state.nettskjemaContent}
                  comments={this.state.comments}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function stripId(Text) {
  return Text.replace(/_.*/, "").trim().toLowerCase().replace(/\s+/g, "-");
}

export default Room;
