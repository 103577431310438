import * as React from "react";
import "../../styles/SystemForNurses.css";
import { Router, Switch, Route } from "react-router-dom";
import RoomSelect from "./RoomSelect";
import Room from "./Room";
import NotFound from "./NotFound";
import FloorSelect from "./FloorSelect";
import DataManagement from "./DataManagement";
import Resources from "./Resources";
import NavMenu from "./NavMenu";
import history from "./history";
import strings from "../../res/strings";
import Information from "./Information";

type MyProps = {
  toothData: any | null;
};

type MyState = {
  localStorageData: Object | null | undefined;
  infoPagesRoutes: any;
  topics: any;
};

class SystemForNurses extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      localStorageData: this.getDataInLocalStorage(),
      infoPagesRoutes: null,
      topics: null,
    };
    this.getDataInLocalStorage = this.getDataInLocalStorage.bind(this);
    this.makeInfoElements = this.makeInfoElements.bind(this);
  }

  getDataInLocalStorage(): any | null {
    let localStorageData = localStorage.getItem(strings.dataKey);
    if (localStorageData && localStorageData !== undefined) {
      localStorageData = JSON.parse(localStorageData);
      return localStorageData;
    } else {
      return null;
    }
  }

  componentDidMount() {
    this.makeInfoElements();
  }

  makeInfoElements() {
    let elements: Array<any> = [];
    let topics: Array<{ title: string; id: string }> = [];
    let content: any = localStorage.getItem(strings.contentFromNettskjemaKey);

    if (content) {
      content = JSON.parse(content);
      if (content && typeof content === "object") {
        for (var c in content) {
          if (content.hasOwnProperty(c)) {
            if (c) {
              let lowercase = c.toLowerCase();
              if (
                lowercase.indexOf("info") === 0 &&
                lowercase.indexOf("&#43") === -1 &&
                lowercase.indexOf("bilde") === -1 &&
                lowercase.indexOf("lenketekst") === -1
              ) {
                let text = content[c];
                let textId = c;
                elements.push(
                  <Route
                    path={"/s/info/" + text}
                    key={textId + "route"}
                    render={(props) => (
                      <Information
                        topic={text}
                        topicId={textId}
                        key={textId}
                        {...props}
                      />
                    )}
                  />,
                );
                elements.push(
                  <Route
                    path={"/s/info/" + textId}
                    key={textId + "route_alt"}
                    render={(props) => (
                      <Information
                        topic={text}
                        topicId={textId}
                        key={textId}
                        {...props}
                      />
                    )}
                  />,
                );
                topics.push({ title: text, id: textId });
              }
            }
          }
        }
      }
    }
    this.setState({
      topics: topics,
      infoPagesRoutes: elements,
    });
  }

  render() {
    return (
      <div className="full">
        <Router history={history}>
          <Switch>
            <Route
              path="/s/data"
              render={(props) => <DataManagement {...props} />}
            />
            <Route
              path="/s/ressurser"
              render={(props) => <Resources {...props} />}
            />
            <Route
              path="/s/etasje/:floorNumber/rom/:roomNumber"
              render={(props) => <Room {...props} />}
            />
            {this.state.infoPagesRoutes}
            <Route
              path="/s/info/"
              render={(prps) => <NotFound {...this.props} />}
            />
            <Route
              path="/s/etasje/:floorNumber"
              render={(props) => <RoomSelect {...props} />}
            />
            <Route
              path="/s/etasje"
              render={(props) => <FloorSelect {...props} />}
            />
            <Route
              render={(props) => {
                if (this.state.localStorageData === undefined)
                  return <h1>Laster inn...</h1>;
                else if (this.state.localStorageData === null)
                  return <DataManagement {...props} />;
                else if (localStorage.getItem(strings.selectedFloorKey)) {
                  return <RoomSelect {...props} />;
                } else {
                  return <FloorSelect {...props} />;
                }
              }}
            />
          </Switch>
        </Router>
        <NavMenu topics={this.state.topics} />
      </div>
    );
  }
}

export default SystemForNurses;
