import * as React from 'react'
import '../../styles/Warning.css'
type MyProps = {
  text: string
}

type MyState = {
}

class Warning extends React.Component<MyProps, MyState> {

  render() {
    return (
        <h4 className="Warning">{this.props.text}</h4>
    )
  }
}

export default Warning