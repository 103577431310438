import * as React from "react";
import { RouteProps } from "react-router";
import history from "./history";

import "../../styles/FloorSelect.css";
import strings from "../../res/strings";
type MyProps = {};

type MyState = {
  data: any | null;
  floorButtonElements: any;
};

class FloorSelect extends React.Component<MyProps & RouteProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      data: null,
      floorButtonElements: null,
    };
    this.createFloorButtons = this.createFloorButtons.bind(this);
  }

  componentDidMount() {
    let data: string | null = localStorage.getItem(strings.dataKey);
    if (data) {
      data = JSON.parse(data);
      this.setState({
        data: data,
        floorButtonElements: this.createFloorButtons(data),
      });
    }
  }

  createFloorButtons(data): Array<any> | null {
    if (data) {
      console.log(data);
      let floorList = new Array();
      for (var patient in data) {
        if (data.hasOwnProperty(patient) && data[patient]["floor"]) {
          floorList.push(data[patient]["floor"]);
        }
      }
      floorList = [...new Set(floorList)];
      floorList.sort((a, b) => parseInt(a) - parseInt(b));
      if (floorList) {
        floorList = floorList.map((f) => (
          <button
            className="squareButton floor"
            key={"fb" + f}
            onClick={() => history.push("/s/etasje/" + f)}
          >
            {f}. etasje
          </button>
        ));
        return floorList;
      }
    }
    return null;
  }

  render() {
    return (
      <div>
        <div className="centerChildrenHorizontaly">
          <h1 className="pageTitle">Etasjeoversikt</h1>
        </div>
        {!this.state.data ? (
          <div>
            <h2 className="subtitle">Fant ingen data å vise frem</h2>
            <button
              className="squareButton"
              onClick={() => history.push("/s/data")}
            >
              Adminstrer data
            </button>
          </div>
        ) : (
          <div>
            <div className="floors">
              {this.state.floorButtonElements ? (
                this.state.floorButtonElements
              ) : (
                <div>
                  <h2>
                    Ingen etasjer har blitt ført inn i systemet i nyligste
                    version av data
                    {this.state.data &&
                      this.state.data.updatedTime &&
                      " (" + this.state.data.updatedTime + ")"}
                  </h2>
                  <button
                    className="squareButton"
                    onClick={() => history.push("/s/data")}
                  >
                    Adminstrer data{" "}
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default FloorSelect;
