import * as React from "react";
import history from "./history";
import "../../styles/NavMenu.css";
import strings from "../../res/strings";

type MyProps = {
  topics: any;
};

type MyState = {
  topics: { title: string; id: string };
  showMenu: boolean;
  backDestinationString: string | null;
  backDestinationPath: string | null;
  previousPath: string | null;
  pageLinkElements: any;
  justGoToPreviousPath: boolean;
  infoSubmenu: boolean;
};

class NavMenu extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      topics: this.props.topics,
      showMenu: false,
      previousPath: null,
      backDestinationPath: null,
      backDestinationString: null,
      justGoToPreviousPath: false,
      pageLinkElements: null,
      infoSubmenu: false,
    };
    this.redirectTo = this.redirectTo.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.getRoomNum = this.getRoomNum.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
    this.updateBackButton = this.updateBackButton.bind(this);
  }

  isInButNotLastIn(stringToBeSearched: string, searchString: string): boolean {
    if (stringToBeSearched === null || searchString === null) return false;
    return (
      stringToBeSearched.lastIndexOf(searchString) > -1 &&
      stringToBeSearched.length -
        (stringToBeSearched.lastIndexOf(searchString) + searchString.length) >
        0
    );
  }

  updateBackButton(newPath: string | null): void {
    if (newPath) {
      if (this.isInButNotLastIn(newPath, "/rom/")) {
        this.setState({
          backDestinationPath: "/s/etasje/" + strings.nullStringKey, //romvelger
          backDestinationString: "Romoversikt",
          justGoToPreviousPath: false,
        });
      } else if (this.isInButNotLastIn(newPath, "/etasje/")) {
        this.setState({
          backDestinationPath: "/s/etasje",
          backDestinationString: "Etasjeoversikt",
          justGoToPreviousPath: false,
        });
      } else if (newPath.indexOf("/ressurser") > -1) {
        this.setState({
          justGoToPreviousPath: true,
        });
      } else if (newPath.indexOf("/data") > -1) {
        this.setState({
          justGoToPreviousPath: true,
        });
      } else if (newPath.indexOf("/info") > -1) {
        this.setState({
          justGoToPreviousPath: true,
        });
      } else if (newPath.indexOf("/s") > -1) {
        if (this.state.previousPath) {
          this.setState({
            backDestinationPath: null,
            backDestinationString: null,
            justGoToPreviousPath: false,
          });
        }
      }
      if (newPath.indexOf("/s") > -1) {
        this.setState({
          previousPath: newPath.substring(newPath.indexOf("/s")),
        });
      }
    }
    this.setState({ showMenu: false });
  }

  redirectTo(s: string | null): void {
    if (this.state.justGoToPreviousPath) {
      window.history.go(-1);
    } else {
      if (s) history.push(s);
      else console.warn("Could not redirect");
    }
  }

  toggleMenu(): void {
    this.setState({
      showMenu: !this.state.showMenu,
      infoSubmenu: false,
    });
  }

  getRoomNum(): string {
    let selectedRoom = localStorage.getItem(strings.selectedRoomKey);
    if (selectedRoom) return " " + selectedRoom;
    return "";
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.hideMenu);
    this.updateBackButton(window.location.href);
    history.listen((location, action) =>
      this.updateBackButton(location.pathname),
    );
  }

  hideMenu(event): any | null {
    let menuHtmlElement = document.getElementById("menu");
    const didClickMenuButton =
      event.target.closest(".topBarItem.right") !== null;

    // Don't handle toggleMenu twice -- if clicked hamburger button, let the
    // button itself handle closing the menu.
    if (didClickMenuButton) {
      return;
    }

    if (
      menuHtmlElement &&
      (!event || (event && !menuHtmlElement.contains(event.target)))
    )
      this.toggleMenu();
  }


  render() {
    return (
      <div className="topBar">
        {this.state.justGoToPreviousPath ||
        (this.state.backDestinationPath && this.state.backDestinationString) ? (
          <button
            className="topBarItem left"
            onClick={() => this.redirectTo(this.state.backDestinationPath)}
          >
            <svg
              className="buttonIcon"
              width="14"
              height="33"
              viewBox="0 0 14 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.4726 0L0.00108361 16.383L0.00347961 16.3847L0 16.3872L11.4715 32.7702L13.1098 31.623L2.44065 16.3859L13.1109 1.14715L11.4726 0Z"
                fill="white"
              />
            </svg>
            {this.state.justGoToPreviousPath
              ? "Tilbake"
              : this.state.backDestinationString}
          </button>
        ) : (
          <div />
        )}
        {this.state.showMenu && (
          <div id="menu">
            {!this.state.infoSubmenu && (
              <>
                <h1 id="menuTitle">Meny</h1>
                <ul id="menuList">
                  <li className="menuItem">
                    <a
                      className="menuLink"
                      href={
                        "/s/etasje/" +
                        strings.nullStringKey +
                        "/rom/" +
                        strings.nullStringKey
                      }
                    >
                      <span>Rom {this.getRoomNum()}</span>
                    </a>
                  </li>
                  <li className="menuItem">
                    <a
                      className="menuLink"
                      href={"/s/etasje/" + strings.nullStringKey + "/rom"}
                    >
                      <span>Romoversikt</span>
                    </a>
                  </li>
                  <li className="menuItem">
                    <a className="menuLink" href={"/s/etasje"}>
                      <span>Etasjeoversikt</span>
                    </a>
                  </li>
                  <li className="menuItem">
                    <a className="menuLink" href={"/s/ressurser"}>
                      <span>Generell informasjon</span>
                    </a>
                  </li>
                  <li className="menuItem">
                    <a className="menuLink" href={"/s/data"}>
                      <span>Oppdater Tannkart</span>
                    </a>
                  </li>
                  <li className="menuItem">
                    <a
                      className="menuLink"
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        this.setState({ infoSubmenu: true });
                      }}
                    >
                      <span>Informasjonssider</span>
                    </a>
                  </li>
                </ul>
              </>
            )}

            {this.state.infoSubmenu && (
              <>
                <button
                  className="menuBackButton"
                  type="button"
                  onClick={(event) => {
                    this.setState({ infoSubmenu: false });
                  }}
                >
                  <svg
                    className="buttonIcon"
                    width="14"
                    height="33"
                    viewBox="0 0 14 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.4726 0L0.00108361 16.383L0.00347961 16.3847L0 16.3872L11.4715 32.7702L13.1098 31.623L2.44065 16.3859L13.1109 1.14715L11.4726 0Z"
                      fill="#000000"
                    />
                  </svg>
                  Tilbake
                </button>
                <ul id="menuList">
                  {/*this.state.pageLinkElements*/}
                  {this.props.topics
                    .filter((topic) => {
                      // Trenger ikke vise alle Tannpuss, full hjelp, delvis
                      // hjelp i menyen. Hvis ikke blir det bla.a. masse
                      // duplikater listet opp da "Delvis hjelp" er tittelen
                      // på flere ulike dokumenter om ulike typer hjelp.
                      const blacklist = [
                        "Tannpuss (Klarer selv)",
                        "Tannpuss (Delvis hjelp)",
                        "Delvis hjelp",
                      ];
                      return blacklist.indexOf(topic.title) === -1;
                    })
                    .map((topic) => (
                      <li className="menuItem" key={topic.id + "link"}>
                        <a className="menuLink" href={"/s/info/" + topic.id}>
                          <span key={topic.id + "menuItem"}>{topic.title}</span>
                        </a>
                      </li>
                    ))}
                </ul>
              </>
            )}
          </div>
        )}
        <button
          className="topBarItem right"
          // Do not call toggleMenu if menu is open; closing is handled
          // elsewhere.
          onClick={this.toggleMenu}
        >
          <svg
            className="buttonIcon"
            width="35"
            height="18"
            viewBox="0 0 35 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="16" width="35" height="1.5" fill="white" />
            <rect y="8" width="35" height="1.5" fill="white" />
            <rect width="35" height="1.5" fill="white" />
          </svg>
          Meny
        </button>
      </div>
    );
  }
}

export default NavMenu;
