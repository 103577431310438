export const teethHaveState = (toothData, state) => {
  for (let tooth of toothData) {
    if (tooth.states && tooth.states[state] && tooth.states[state].answer) {
      return true;
    }
  }
  return false;
};

export const countWhiteTeeth = (toothData) => {
  let sum = 0;

  for (let tooth of toothData) {
    let toothOk = true;
    if (tooth.states && Object.keys(tooth.states).length > 0) {
      for (let s of Object.keys(tooth.states)) {
        switch (s) {
          case "missing":
          case "partProsthesis":
          case "wholeProsthesis":
          case "bridge":
          case "bridgeConnection":
          case "crown":
          case "rest":
          case "implant":
            toothOk = false;
            break;
          case "coating":
            break; // Tooth still ok
        }
      }
    }
    if (toothOk) {
      sum++;
    }
  }
  return sum;
};

// Antall delproteser
export const numPartProstheses = (toothData) => {
  let sum = 0;
  for (let tooth of toothData) {
    if (
      tooth.states &&
      tooth.states.partProsthesis &&
      tooth.states.partProsthesis.answer
    ) {
      sum++;
    }
  }
  return sum;
};

// Antall helproteser
export const numWholeProstheses = (toothData) => {
  let sum = 0;
  for (let tooth of toothData) {
    if (
      tooth.states &&
      tooth.states.wholeProsthesis &&
      tooth.states.wholeProsthesis.answer
    ) {
      sum++;
    }
  }
  return sum;
};

export const formatDate = (date: string) => {
  const re = /^(\d{4})-(\d{2})-(\d{2})T(.+)$/;
  const ex = re.exec(date);
  if (ex !== null) {
    const [original, year, month, day, time] = ex;
    return `${day}.${month}.${year}`;
  }
  return date;
};
