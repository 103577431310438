const colors = {
  tooth: {
    fill: {
      default: "#ffffff",
      wholeProsthesis: "#3D3DC9",
      crown: "#7EF4F4",
      missing: "#C4C4C4",
    },
    stroke: {
      highlighted: "#3D3DC9 ",
      hovered: "#8995FF",
      notHighlighted: "black",
      missing: "#505050",
    },
    other: {
      partProthesis: "#5C7CF0",
      rests: "#8C530F",
      implant: "#343333",
      bridge: "#000000",
      coating: "#FFEFB7",
    },
    simplified: {
      permanentProsthesis: "#83F4F3",
      removableProsthesis: "#3D3DC9",
    },
  },
};

export default colors;
