import * as React from "react";
import "../../styles/Resources.css";
import strings from "../../res/strings";
type MyProps = {};

type MyState = {
  nettskjemaResourcesContent: null | string;
};

class Resources extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      nettskjemaResourcesContent: null,
    };
  }

  componentDidMount() {
    let nettskjemaContent = localStorage.getItem(
      strings.contentFromNettskjemaKey,
    );
    if (nettskjemaContent) {
      nettskjemaContent = JSON.parse(nettskjemaContent);
      if (nettskjemaContent && nettskjemaContent[strings.resourcesKey]) {
        if (
          !(
            nettskjemaContent[strings.resourcesKey].includes("javascript:") ||
            nettskjemaContent[strings.resourcesKey].includes("script") ||
            nettskjemaContent[strings.resourcesKey].includes("onclick")
          )
        ) {
          //TODO improve check/clean
          this.setState({
            nettskjemaResourcesContent: nettskjemaContent[strings.resourcesKey],
          });
        } else {
          console.warn("This HTML can not include js");
        }
      }
    }
  }

  render() {
    return (
      <div>
        <div className="centerChildrenHorizontaly">
          <h1 className="pageTitle">Generell informasjon</h1>
        </div>
        {this.state.nettskjemaResourcesContent ? (
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.nettskjemaResourcesContent,
            }}
          /> //TODO lag bedre løsning enn dangerouslyset
        ) : (
          <div>
            <a
              href="https://www.oslo.kommune.no/helse-og-omsorg/helsehjelp/tannhelse-og-tannlege/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tannhelsetjenesten - Oslo kommune
            </a>
            <br />
            <br />
            <a
              href="https://www.oslo.kommune.no/helse-og-omsorg/eldreomsorg/sykehjem-og-dagsenter/alle-sykehjem/st-hanshaugen-omsorgssenter/"
              target="_blank"
              rel="noopener noreferrer"
            >
              St. Hanshaugen Omsorgssenter
            </a>
          </div>
        )}
      </div>
    );
  }
}

export default Resources;
