import * as React from "react";
import { formatDate } from "../../utils";

type MyProps = {
  textContent: any;
  comments: Array<any>;
};

type MyState = {};

class Comments extends React.Component<MyProps, MyState> {
  render() {
    const { textContent } = this.props;
    const subtitle = textContent && textContent["4"];

    return (
      <div className="Comments roomBox scrollable">
        <div className="roomBoxScrollableContent">
          {subtitle && this.props.comments.length > 0 ? (
            <div>
              <h2 className="subtitle">{subtitle}</h2>
              <ul className="commentList">
                {this.props.comments.map((comment) => (
                  <li className="comment" key={"comment-" + comment.date}>
                    <b>{formatDate(comment.date)}:</b>
                    {" " + comment.content}
                    <br />
                    {"-" + comment.by}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <h2 className="subtitle">Ingen kommentarer fra tannpleier</h2>
          )}
        </div>
      </div>
    );
  }
}

export default Comments;
