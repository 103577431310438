import * as React from "react";
import "../../styles/DataManagement.css";
import { RouteProps } from "react-router";
import history from "./history";
import strings from "../../res/strings";
import he from "he";

type MyProps = {};

type MyState = {
  info: string | null;
  loggedIn: boolean;
  waitingForData: boolean;
};

class DataManagement extends React.Component<MyProps & RouteProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      info: null,
      loggedIn: false,
      waitingForData: true,
    };
    this.clearData = this.clearData.bind(this);
    this.getContentFromNettskjema = this.getContentFromNettskjema.bind(this);
    this.updateData = this.updateData.bind(this);
    this.copyDataForOpus = this.copyDataForOpus.bind(this);
    this.copyDataForGerica = this.copyDataForGerica.bind(this);
    this.clearInfoText = this.clearInfoText.bind(this);
    this.storeResponseInLocalStorage = this.storeResponseInLocalStorage.bind(
      this,
    );
    this.logIn = this.logIn.bind(this);
    this.getDataFromTSD = this.getDataFromTSD.bind(this);
  }

  componentDidMount() {
    let dataFromLocalStorage = localStorage.getItem(strings.dataKey);
    if (dataFromLocalStorage)
      dataFromLocalStorage = JSON.parse(dataFromLocalStorage);
    if (
      dataFromLocalStorage &&
      dataFromLocalStorage["updatedTime"] &&
      dataFromLocalStorage["updatedBy"]
    )
      this.setState({
        info:
          "Data ble sist oppdatert " +
          dataFromLocalStorage["updatedTime"] +
          " av " +
          dataFromLocalStorage["updatedBy"],
      });
    else if (!dataFromLocalStorage)
      this.setState({ info: "Ingen data har blitt hentet til denne enheten" });
    else {
      console.warn("data ble funnet uten forventa felt");
      console.log(dataFromLocalStorage);
    }
  }

  clearData(): boolean {
    localStorage.clear();
    this.setState({ info: "Data ble fjernet fra denne enheten" });
    return true;
  }

  updateData(): void {
    this.getContentFromNettskjema();

    // Old data fetching from Nettskjema:
    // this.getDataFromTSD();
  }

  getContentFromNettskjema(): void {
    this.setState({ waitingForData: true }, () => {
      fetch("/nettskjema/content")
        .then((res) => {
          if (res) {
            if (res.ok) {
              res.json().then((jsonRes) => {
                if (typeof jsonRes === "undefined" || jsonRes === null) {
                  console.error("Could not parse JSON from response:");
                  console.log(res);
                } else {
                  if (jsonRes && jsonRes.elements) {
                    console.log(jsonRes);
                    this.storeResponseInLocalStorage(jsonRes); //TODO test på uio nett
                  } else {
                    console.warn("Nettskjema responded without elements:");
                    console.log(jsonRes);
                  }
                }
              });
            } else {
              console.error(res);
              throw new Error(
                "Response was rejected:" + res.status + ": " + res.statusText,
              );
            }
          } else {
            console.error(res);
            throw new Error("Response is null");
          }
        })
        .catch(() => {
          if (true) {
            //TODO fjern etter testing
            console.log("Later som om vi fikk data likevel for å teste:");
            this.storeResponseInLocalStorage(
              require("./backUpDataForTesting.json"),
            );
          }
        });
    });
  }

  storeResponseInLocalStorage(jsonRes: any): void {
    let contentToBeStored: Object = {};
    let counter = 0;
    for (let i = 0; i < jsonRes.elements.length; i++) {
      if (jsonRes.elements[i]) {
        let contentText: string;
        if (jsonRes.elements[i].title || jsonRes.elements[i].description) {
          if (jsonRes.elements[i].title) {
            contentText = he.decode(jsonRes.elements[i].title);
          } else {
            contentText = jsonRes.elements[i].description;
          }
          if (contentText) {
            let withoutIDPrefix = contentText.split("{ID:");
            if (
              withoutIDPrefix !== null &&
              withoutIDPrefix[1] !== undefined &&
              withoutIDPrefix[0] !== undefined
            ) {
              let withoutIDSuffix = withoutIDPrefix[1].split("}");
              if (
                withoutIDSuffix !== null &&
                withoutIDSuffix[0] !== undefined &&
                withoutIDSuffix[1] !== undefined
              ) {
                let ID = withoutIDSuffix[0].toLowerCase();
                if (ID.includes("overskrift") || ID.includes("tekst")) {
                  counter++;
                  let uniqueId: string = "&#43;" + counter.toString();
                  ID += uniqueId;
                }
                let contentWithoutID: string =
                  withoutIDPrefix[0] + withoutIDSuffix[1].trim();
                withoutIDPrefix.shift();
                withoutIDPrefix.shift();
                contentWithoutID += withoutIDPrefix.join();
                if (
                  !(
                    ID.includes("html") ||
                    (ID.includes("tekst") && !ID.includes("lenketekst"))
                  )
                ) {
                  contentWithoutID = contentWithoutID
                    .replace(/<[^>]+>/g, "")
                    .replace(/\n/gi, "");
                }
                contentToBeStored[ID] = contentWithoutID;
              }
            }
          }
        } else if (
          jsonRes.elements[i].image &&
          jsonRes.elements[i].image.imageId &&
          jsonRes.elements[i].altText
        ) {
          //TODO test//For images directly uploaded in nettskjema
          counter++;
          let uniqueId: string = " bildeID:" + counter.toString();
          contentToBeStored[jsonRes.elements[i].altText + uniqueId] =
            "https://nettskjema.no/image/" + jsonRes.elements[i].image.imageId;
        } else if (
          jsonRes.elements[i].imageUrl &&
          jsonRes.elements[i].altText
        ) {
          //For images uploaded in nettskjema by external url
          counter++;
          let uniqueId: string = " bildeID:" + counter.toString();
          contentToBeStored[jsonRes.elements[i].altText + uniqueId] =
            jsonRes.elements[i].imageUrl;
        }
      }
    }
    localStorage.setItem(
      strings.contentFromNettskjemaKey,
      JSON.stringify(contentToBeStored),
    );

    this.storePathsAndAliases(contentToBeStored);

    this.setState({ waitingForData: false }, () => {
      // Get TSD data when thee other content has been stored.
      window.location.href = "/s/login/idporten";
    });
  }

  storePathsAndAliases(jsonContent: any) {
    let tempObj = {};
    for (var contentTag in jsonContent) {
      if (jsonContent.hasOwnProperty(contentTag)) {
        let contentTagID = contentTag.split(/[^A-Za-z0-9]/);
        if (contentTagID && contentTagID[0]) {
          let lowerCaseTag = contentTag.toLowerCase();
          if (
            contentTag.indexOf("info") === 0 &&
            contentTag.indexOf("&#43") === -1 &&
            contentTag.indexOf("bilde") === -1 &&
            contentTag.indexOf("lenkeTekst") === -1
          ) {
            if (tempObj[contentTagID[0]] === undefined) {
              tempObj[contentTagID[0]] = {};
            }
            tempObj[contentTagID[0]].path = jsonContent[contentTag]; //.replace(/<[^>]+>/g, "").replace(/\n/ig, "")
          } else if (lowerCaseTag.indexOf("lenketekst") > -1) {
            let links = jsonContent[contentTag]; //.replace(/<[^>]+>/g, "").replace(/\n/ig, "")
            links = links.split(",");
            if (links) {
              links = links.map(function (link) {
                if (link) {
                  return link.toLowerCase().trim();
                } else {
                  return Array(4).join(strings.missingData);
                }
              });
              links = links.map((link) => link.toLowerCase().trim());
              links.sort((a, b) => b.length - a.length);
              if (tempObj[contentTagID[0]] === undefined) {
                tempObj[contentTagID[0]] = {};
              }
              tempObj[contentTagID[0]].aliases = links;
            }
          }
        }
      }
    }
    localStorage.setItem(
      strings.externalTopicsToLinkKey,
      JSON.stringify(tempObj),
    );
  }

  async getDataFromTSD() {
    //TODO når ekko har satt opp sin løsning
    // const tsdData = require("./TSDMockData").default;

    const realData = await fetch("/nettskjema/submissions")
      .then((a) => a.json())
      .then((a) => {
        console.log(a);
        return a;
      });

    let tsdData = {};

    for (let i = 0; i < realData.length; i++) {
      tsdData["[navn/fnr/id]" + i] = Object.assign({}, realData[i]);
    }

    if (tsdData) {
      let now: string = new Date().toLocaleString();
      tsdData["updatedBy"] = "brukernavn"; //TODO strip fnr, additionaldata
      tsdData["updatedTime"] = now;
      localStorage.setItem(strings.dataKey, JSON.stringify(tsdData));
      this.setState({ info: "Data ble oppdatert " + now });
    } else {
      this.setState({ info: "Data kunne ikke oppdateres" });
    }
  }

  copyTextToClipboard(txtToCopy: string): void {
    const tempElementForSafeCopy = document.createElement("textarea");
    tempElementForSafeCopy.value = txtToCopy;
    document.body.appendChild(tempElementForSafeCopy);
    tempElementForSafeCopy.select();
    document.execCommand("copy");
    document.body.removeChild(tempElementForSafeCopy);
  }

  copyDataForOpus(): void {
    //TODO fiks
    if (false) {
      //this.copyTextToClipboard(JSON.stringify(this.state.additionalDataNotForLocalStorage))//TODO erstatt med ordentlig formatering tilpasset systemet
      this.setState({ info: "Data ble kopiert og kan limes inn i Opus" });
    } else {
      this.setState({
        info: "Ingen data for pasient. Prøv gjerne å oppdatere ny data først.",
      });
    }
  }

  copyDataForGerica(): void {
    //TODO duplikat
    if (false) {
      //this.copyTextToClipboard(JSON.stringify(this.state.additionalDataNotForLocalStorage))//TODO erstatt med ordentlig formatering tilpasset systemet
      this.setState({ info: "Data ble kopiert og kan limes inn i Gerica" });
    } else {
      this.setState({
        info: "Ingen data for pasient. Prøv gjerne å oppdatere ny data først.",
      });
    }
  }

  clearInfoText(): void {
    this.setState({ info: null });
  }

  logIn(): void {
    this.setState({ loggedIn: true });
  }

  render() {
    const url = window.location.toString();
    const status = url.includes("success=false")
      ? "failure"
      : url.includes("success=true")
      ? "success"
      : null;
    return (
      <div>
        <div className="centerChildrenHorizontaly">
          <h1 className="pageTitle">Oppdater Tannkart</h1>
        </div>
        <div className="dataButtons">
          <div>
            <button className="squareButton" onClick={this.updateData}>
              Oppdater data
            </button>

            <button className="squareButton" onClick={this.clearData}>
              Glem data
            </button>
          </div>
          {status === "failure" && (
            <p style={{ marginBottom: 0, fontWeight: "bold" }}>
              Kunne ikke oppdatere data
            </p>
          )}
          {this.state.info !== null && (
            <h3 className="copyInfo" onClick={this.clearInfoText}>
              {this.state.info}
            </h3>
          )}
          {/*<div>
              <button className={"squareButton" + (this.state.additionalDataNotForLocalStorage ? " enabled" : " disabled")} onClick={this.copyDataForOpus}>Kopier data i Opus format</button>
              <button className={"squareButton" + (this.state.additionalDataNotForLocalStorage ? " enabled" : " disabled")} onClick={this.copyDataForGerica}>Kopier data i Gerica format</button>
            </div>*/}
          <button
            className="squareButton tt"
            onClick={() => {
              const isSameLocationRegex = /^\/s(\/)?$/; // "/s/" || "/s"
              if (isSameLocationRegex.test(window.location.pathname)) {
                window.location.reload();
              } else {
                history.push("/s/");
              }
            }}
          >
            Til Tannkart
          </button>
        </div>
      </div>
    );
  }
}

export default DataManagement;
