const incompatibleStates = {//Codebookname: Array of codebooknames that are not compatible
  //TODO dobbeltlagring for enklere lesing. kan nok forbedres
  4240933: [4240930, 4240932, 4240934, 4240936, 4240935],//bro
  4240934: [4240933, 4240930, 4240932, 4240937, 4240935],//broledd
  4270476: [4240930],//belegg
  4240935: [4240937, 4240930, 4240932, 4240933, 4240934, 4240936],//krone
  4240937: [4240930, 4240932, 4240934, 4240935, 4240936],//implantat
  4240930: [4240933, 4240934, 4270476, 4240935, 4240937, 4240931, 4240936, 4240932],//mangler
  4240931: [4240932, 4240930],//delprotese
  4240936: [4240933, 4240930, 4240935, 4240937],//rester
  4240932: [4240931, 4240930, 4240933, 4240934, 4240935, 4240937]//helprotese
}
export default incompatibleStates